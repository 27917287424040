import { Component,CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import '@maersk-global/mds-components-core/mc-input';
import { Equipment } from '../../../models/equipment.model';


@Component({
    selector: 'app-cwo-container-details',
    standalone: true,
    templateUrl: './cwo-container-details.component.html',
    styleUrl: './cwo-container-details.component.scss',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule]
})
export class CreateWorkOrerContainerDetailsComponent{
  equipment = input.required<Equipment>();
  shopLocationCode = input.required<string|undefined>();
  }
