import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnInit } from '@angular/core';
import { TableColumn } from '@maersk-global/mds-components-core/mc-table/types';
import { McTable } from '@maersk-global/mds-components-core';
import { getTPIChar } from '../../../models/tpi.model';
import { utils } from '../../../helper/utils';
import { MaintenanceOrderLineItem } from '../../../models/maintenance-order-line-item';
import { AemsFile } from '../../../models/aems-file.model';
import { FileUploadComponent } from '../../aems-file-upload/file-upload.component';

type part={
  maintenanceOrderPartNumber:string;
  maintenanceOrderPartDescription:string;
  maintenanceOrderPartCost:number;
  importTaxApplicable:string;
}
type repairLineItem={
  id:string;
  damageCode:string;
  repairCode:string;
  description:string;
  repairLocationCode:string;
  tpiCode:string;
  pcs:number;
  manHoursPerPiece:number;
  materialCostPerPiece:number;
  itemTotal:number;
  parts:part[]
  files:AemsFile[]
}
@Component({
  selector: 'app-list-repair-line-items',
  standalone: true,
  imports: [FileUploadComponent],
  templateUrl: './list-repair-line-items.component.html',
  styleUrl: './list-repair-line-items.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ListRepairLineItemsComponent implements OnInit {
  ngOnInit(): void {
    console.log("ng init fired for ist repair line items")
    this.mapData();

  }
  @Input({ required: true }) data: MaintenanceOrderLineItem[] = [];
  @Input({ required: true }) shopCurrency!: string;
  tableData:repairLineItem[]=[];
  repairLineItemTableColumns: Array<TableColumn> = [
    {
      id: 'damageCode',
      label: 'Damage Code',
      sortDisabled:true
    },
    {
      id: 'repairCode',
      label: 'Repair Code',
      sortDisabled:true
    },
    {
      id: 'description',
      label: 'Description',
      sortDisabled:true
    },
    {
      id: 'repairLocationCode',
      label: 'Repair Loc Code',
      sortDisabled:true
    }, {
      id: 'tpiCode',
      label: 'TPI Code',
      sortDisabled:true
    }, {
      id: 'pcs',
      label: 'Pcs',
      sortDisabled:true
    }, {
      id: 'manHoursPerPiece',
      label: 'Man Hours /Pc',
      sortDisabled:true,
      align:"right"
    }, {
      id: 'materialCostPerPiece',
      label: 'Material Cost/pc',
      sortDisabled:true,
      align:"right"

    }, {
      id: 'itemTotal',
      label: 'Item Total',
      sortDisabled:true,
      align:"right"
    },
    {
      id:'attachments',
      label:'',
      sortDisabled:true
    }
  ]
  partsTableColumn:Array<TableColumn>=[
    {
      id: 'maintenanceOrderPartNumber',
      label: 'Part Number',
      sortDisabled:true
    },
    {
      id: 'maintenanceOrderPartDescription',
      label: 'Part Description',
      sortDisabled:true
    },
    {
      id: 'maintenanceOrderPartCost',
      label: 'Part Cost',
      sortDisabled:true
    },
    {
      id: 'oldPartSerialNumber',
      label: 'Old Part Serial Number',
      sortDisabled:true
    },
    {
      id: 'importTaxApplicable',
      label: 'Import Tax',
      sortDisabled:true
    },
  ]

  mapData(){
    this.tableData= this.data.map(item=>{
      return {
        id:item.maintenanceTypeCode+item.maintenanceRepairLocationCode,
        damageCode:item.equipmentDamageCode,
        repairCode:item.maintenanceTypeCode,
        description:item.maintenanceTypeName ?? 'Not Available',
        repairLocationCode:item.maintenanceRepairLocationCode,
        tpiCode:getTPIChar(item.thirdPartyIndicator),
        pcs:item.repairQuantity,
        manHoursPerPiece:item.manHoursPerPiece,
        materialCostPerPiece:utils.formatNumberToTwoDigitDecimal(item.materialCostPerPiece),
        files:item.blobResolvedFiles??[],
        itemTotal:utils.formatNumberToTwoDigitDecimal((item.repairQuantity*item.materialCostPerPiece)+item.maintenanceOrderParts.reduce((accumulator, currentPart) => accumulator + (currentPart.unitPrice*currentPart.unitQuantity), 0)),
        parts:item.maintenanceOrderParts.map(part=>{
          return{
            maintenanceOrderPartNumber:part.partNumber,
            maintenanceOrderPartDescription:part.partName ?? "Not Available",
            maintenanceOrderPartCost:utils.formatNumberToTwoDigitDecimal(part.unitPrice),
            oldPartSerialNumber:part.replacedSerialNumbers?.[0],
            importTaxApplicable:"NA"


          }
        })
      }
    })
  }
}
