import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, Routes, UrlTree } from '@angular/router';
import { ListWorkOrdersComponent } from './views/list-work-orders/list-work-orders.component';
import { AuthCallback } from './auth/auth-callback.component';
import { ShellComponent } from './components/shell/shell.component';
import { CreateWorkOrderComponent } from './views/create-work-order/create-work-order.component';
import { AuthHelper } from './auth/auth-helper';
import { utils } from './helper/utils';
import { ForbiddenComponent } from './views/forbidden/forbidden.component';
import { AuthPolicies } from './auth/auth-policies';
import { inject } from '@angular/core';
import { ViewWorkOrderComponent } from './views/view-work-order/view-work-order.component';


const userAuthenticatedRouteGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot)=>{
  if(!AuthHelper.isAccessTokenPresentAndValid()){
    AuthHelper.redirectToLogin();
  }
  return AuthHelper.isAccessTokenPresentAndValid();
}

const isUserShopOrAdminRouteGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot)=>{
  let authPolicies:AuthPolicies=inject(AuthPolicies);
  console.log(authPolicies);
  if(authPolicies.canCreateWorkOrder){
   return true;
  }
  window.location.href="/forbidden";
  return false;
}


export const APP_ROUTES: Routes = [
  {
    path: '',
    component: ShellComponent,
    canActivate:[userAuthenticatedRouteGuard],
    children: [
      {
        path: 'workorders/edit/:workorderId',
        component: CreateWorkOrderComponent,
        canActivate:[userAuthenticatedRouteGuard,isUserShopOrAdminRouteGuard]
      },
      {
        path: 'workorders/create',
        component: CreateWorkOrderComponent,
        canActivate:[userAuthenticatedRouteGuard,isUserShopOrAdminRouteGuard]
      },
      {
        path: 'workorders',
        component: ListWorkOrdersComponent,
        canActivate:[userAuthenticatedRouteGuard]
      },
      {
        path:'workorders/:id',
        component:ViewWorkOrderComponent,
        canActivate:[userAuthenticatedRouteGuard]

      },
      {
        path:'reports',
        loadComponent:()=>import('./views/report/report.component').then(mod=>mod.ReportComponent),
        canActivate:[userAuthenticatedRouteGuard]

      },
      {
        path: '',
        component: ListWorkOrdersComponent,
        canActivate:[userAuthenticatedRouteGuard],
        pathMatch:'full'
      },
    ],
  },
  {
    path: 'oauth2/callback',
    component: AuthCallback,
  },
  {
    path:'forbidden',
    component:ForbiddenComponent
  }
];
