<form [formGroup]="workOrderFilterForm">
    <div class="pure-g">
        <div class="pure-u-md-24-24">
            <div class="pure-u-md-12-24 p-b-16 p-r-16"><mc-input name="equipmentId" ngDefaultControl
                    formControlName="equipmentId" fit="medium" placeholder="Enter Equipment ID" label="Equipment ID"
                    [attr.invalid]="isControlInErrorState(this.workOrderFilterForm.controls.equipmentId)">
                    @if(this.workOrderFilterForm.controls.equipmentId.hasError('pattern')){
                    <span slot="errormessage">Incorrect equipment id</span>
                    }
                </mc-input></div>
            <div class="pure-u-md-12-24 p-b-16 p-r-16"><mc-multi-select label="Mode" fit="medium" width="100"
                    placeholder="Select an Option" ngDefaultControl formControlName="mode">
                    @for (mode of modes; track mode){
                    <mc-option [value]="mode">{{ mode.code }} {{ mode.description }}</mc-option>
                    }
                </mc-multi-select></div>
            <div class="pure-u-md-12-24 p-b-16 p-r-16"><mc-input name="woNumber" ngDefaultControl
                    formControlName="woNumber" fit="medium" placeholder="Enter WO Number" label="WO Number"
                    [attr.invalid]="isControlInErrorState(this.workOrderFilterForm.controls.woNumber)">
                    @if(this.workOrderFilterForm.controls.woNumber.hasError('pattern')){
                    <span slot="errormessage">Incorrect workorder number</span>
                    }</mc-input>
            </div>
            <div class="pure-u-md-12-24 p-b-16 p-r-16"><mc-multi-select label="Status" fit="medium" width="100"
                    placeholder="Select an Option" ngDefaultControl formControlName="status">
                    @for (status of statuses; track status){
                    <mc-option [value]="status">{{status.description}}</mc-option>
                    }
                </mc-multi-select></div>
            <div class="pure-u-md-12-24 p-b-16 p-r-16"><mc-input-date label="From" startofweek="1" format="DD-MM-YYYY"
                    nextlabel="Next month" previouslabel="Previous month" placeholder="DD-MM-YYYY" width="auto"
                    ngDefaultControl formControlName="fromDate">
                </mc-input-date></div>
            <div class="pure-u-md-12-24 p-b-16 p-r-16"><mc-input-date label="To" startofweek="1" format="DD-MM-YYYY"
                    nextlabel="Next month" previouslabel="Previous month" placeholder="DD-MM-YYYY" width="auto"
                    ngDefaultControl formControlName="toDate">
                </mc-input-date></div>
            <div class="pure-u-md-12-24 p-b-16 p-r-16"><mc-multi-select label="Shop ID" fit="medium" width="100"
                    placeholder="Select an Option" ngDefaultControl formControlName="shop">
                    @for (shop of shops; track shop){
                    <mc-option [value]="shop">{{ shop.code }} {{ shop.description }}</mc-option>
                    }
                </mc-multi-select></div>
            <br />
            <div class="pure-u-md-12-24 p-b-16 p-r-16"><mc-checkbox [checked]="workOrderFilterForm.controls.showFlaggedWO.value" 
                    name="showFlaggedWO" label="Show only flagged work orders" ngDefaultControl
                    formControlName="showFlaggedWO">
                </mc-checkbox></div>
        </div>
        <div class="pure-u-md-24-24 utility-buttons">
            <mc-button label="Apply Filters" slot="primaryAction" variant="filled" appearance="primary" fit="medium"
                (click)="applyFilters()" class="p-r-16"></mc-button>
            <mc-button label="Clear Filters" slot="secondaryAction" appearance="neutral" fit="medium"
                (click)="clearFilters()" class="p-r-16"></mc-button>
        </div>
    </div>
</form>