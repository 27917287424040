import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { catchError, map, of, tap } from 'rxjs';
import { Equipment } from '../../../../models/equipment.model';
import { WarningSharedService } from '../../warning-shared-service';

export function duplicateRepairCodeValidator(
  index: number,
  equipment: Equipment | null,
  warningSharedService: WarningSharedService,
): ValidatorFn {
  return (control: AbstractControl) => {
    var repairCode = control.value as string;
    if (!repairCode)
      return null;
    if (!equipment)
      return null;
    if (!equipment.lastWorkOrderRepairCodes)
      return null;
    let validatorName = duplicateRepairCodeValidator.name + index;
    let duplicateRapair = equipment.lastWorkOrderRepairCodes.find(x => x == repairCode)
    if (duplicateRapair) {
      let warningObj = {
        validatorName: validatorName,
        message: 'Duplicate work order found with previous empty move.'
      }
      warningSharedService.addWarning(warningObj);
    } else {
      warningSharedService.deleteWarning(validatorName)
    }

    return null;
  }
}
