import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, signal, effect, computed, WritableSignal, ChangeDetectorRef, ChangeDetectionStrategy, Signal, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  FormBuilder,
  ReactiveFormsModule,
  FormControlStatus,
  FormGroup,
  FormArray,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { ErrorComponent } from '../../components/error/error.component';
import { CreateWorkOrderHeaderComponent }
  from "../../components/create-work-order/header/cwo-header.component";
import { CreateWorkOrerContainerDetailsComponent }
  from "../../components/create-work-order/container-details/cwo-container-details.component";
import { CreateWorkOrderAttachmentsComponent }
  from '../../components/create-work-order/attachments/cwo-attachments.component';
import { CreateWorkOrderLineItemsComponent }
  from '../../components/create-work-order/line-items/cwo-line-items.component';
import { CreateWorkOrderRemarksComponent }
  from '../../components/create-work-order/remarks/cwo-remarks.component';
import '@maersk-global/mds-components-core/mc-button';
import { CreateWorkOrderAlarmDetailsComponent }
  from '../../components/create-work-order/cwo-alarm-details/cwo-alarm-details.component';
import { WorkOrderService } from '../../services/work-order.service';
import { Mode, DamageType } from '../../models/mode.model';
import { Shop } from '../../models/shop.model';
import { Equipment } from '../../models/equipment.model';
import { HeaderForm } from '../../form-model/cwo-header.form.model';
import { MasterDataService } from '../../services/master-data.service';
import { catchError, combineLatest, debounceTime, distinctUntilChanged, firstValueFrom, forkJoin, of, pipe, ReplaySubject, takeLast, takeUntil, throttle, throttleTime, throwError } from 'rxjs';
import { CodeDescription } from '../../models/code-description.model';
import { IMcTypeaheadData } from '@maersk-global/mds-components-core/mc-typeahead/types';
import { ContainerGrade } from '../../models/container-grade.model';
import { WarningComponent } from '../../components/warning/warning.component';
import { WarningSharedService } from '../../components/create-work-order/warning-shared-service';
import { DamageAndRepairForm } from '../../form-model/damage-and-repair.form.model';
import { RemarkForm } from '../../form-model/cwo-remark.form.model';
import { CreateWorkOrderManHoursComponent } from '../../components/create-work-order/man-hours/cwo-man-hours/cwo-man-hours.component';
import { ManHoursForm } from '../../form-model/cwo-man-hours.form.model';
import { CreateWorkOrderTotalCostComponent } from '../../components/create-work-order/total-cost/cwo-total-cost/cwo-total-cost.component';
import { ToastrService } from '../../services/toastr-service';
import { utils } from '../../helper/utils';
import { AttachmentForm } from '../../form-model/attachment.form.model';
import { WorkOrderSubmitErrorModel } from '../../models/workorder-submit-error.model';
import { HttpErrorResponse } from '@angular/common/http';
import { RepairLineItemForm } from '../../form-model/cwo-repair-line-item.form.model';
import { MaintenanceOrderRemarkScope } from '../../models/remark-scope.model';
import { MaintenanceOrderRemark } from '../../models/maintenance-order-remark.model';
import { TotalCostForm } from '../../form-model/total-cost.form.model';
import { shopLimitValidator } from './validators/shop-limit.validator';
import { ThirdPartyIndicator, getTPIChar } from '../../models/tpi.model';
import { MaintenanceOrderResponse } from '../../models/maintenance-order-response.model';
import { MaintenanceOrderCreateRequest } from '../../models/maintenance-order-create-request.model';
import { MaintenanceOrderLineItem } from '../../models/maintenance-order-line-item';
import { AttachedImage } from '../../models/maintenance-order-attached-image';
import { TotalCost } from '../../models/total-cost-model';
import { RepairLocation } from '../../models/repair-location.model';
import { Repair } from '../../models/repair.model';
import { PrincipalProperty } from '../../models/principal-property.model';
import { clone } from 'lodash-es';
import { EditWorkOrderHeaderComponent } from '../../components/edit-work-order/header/edit-work-order-header/edit-work-order-header.component';
import { SharedDataService } from '../../services/shared-data.service';
import { APICallStatus } from '../../models/api-call-status.mode';
import { MaintenanceOrderManHours } from '../../models/maintenance-order-man-hours';
import { Currency } from '../../models/currency.model';

@Component({
  selector: 'app-create-work-order',
  standalone: true,
  imports: [
    RouterModule,
    ReactiveFormsModule,
    CreateWorkOrderHeaderComponent,
    CreateWorkOrerContainerDetailsComponent,
    CreateWorkOrderAttachmentsComponent,
    CreateWorkOrderLineItemsComponent,
    CreateWorkOrderManHoursComponent,
    CreateWorkOrderRemarksComponent,
    CreateWorkOrderAlarmDetailsComponent,
    CreateWorkOrderTotalCostComponent,
    WarningComponent,
    CommonModule,
    ErrorComponent,
    NgxSkeletonLoaderModule,
    EditWorkOrderHeaderComponent
  ],
  templateUrl: './create-work-order.component.html',
  styleUrl: './create-work-order.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CreateWorkOrderComponent implements OnInit {
  @Input() workorderId: string | undefined = undefined;

  // Properties for this component
  formSubmitErrors = signal<string[]>([]);
  headerFormValid = signal<boolean>(false);
  submitLoader: boolean = false;
  warnings = this.warningSharedService.getWarnings();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  openCancelConfirmation: boolean = false;
  isEditMode = signal(false);
  workorder: MaintenanceOrderResponse | undefined;
  workOrderCurrency: Currency | undefined;
  calculateTotalApiCallStatus: APICallStatus = "NOT_STARTED";

  // Properties for man hours component
  totalManhoursWithoutPrepTime = 0;
  manHoursThatQualifyForPrepTime = 0;
  isPrepTimeApplicable=false;


  // Properties coming from header and must for the work order
  mode!: Mode;
  shop!: Shop
  equipment!: Equipment;

  // Properties for repair line items components
  repairs: Repair[] = [];
  damages: CodeDescription[] = [];
  repairLocations: RepairLocation[] = [];
  tpiCodes: ThirdPartyIndicator[] = [];
  containerGrades: ContainerGrade[] = [];
  gettingDataFromAPIForRepairLineItemsForm = signal(true);

  // The parent form of all the forms
  createWorkOrderForm = this.formBuilder.group({
    headerForm: this.formBuilder.group<HeaderForm>({} as HeaderForm),
    attachmentForm: this.formBuilder.group<AttachmentForm>({} as AttachmentForm),
    damageAndRepairForm: this.formBuilder.group<DamageAndRepairForm>({} as DamageAndRepairForm),
    manHoursForm: this.formBuilder.group<ManHoursForm>({} as ManHoursForm),
    remarkForm: this.formBuilder.group<RemarkForm>({} as RemarkForm),
    totalCostForm: this.formBuilder.group<TotalCostForm>({} as TotalCostForm)
  }, { validators: shopLimitValidator() });


  maintenanceOrderModel: MaintenanceOrderCreateRequest = {} as MaintenanceOrderCreateRequest;
  maintananceOrderModelOnSubmit: MaintenanceOrderCreateRequest = {} as MaintenanceOrderCreateRequest;



  cost: TotalCost | null = null;;
  constructor(private workOrderService: WorkOrderService, private formBuilder: FormBuilder,
    private masterDataService: MasterDataService, private warningSharedService: WarningSharedService,
    private toastr: ToastrService, private router: Router, private changeDetector: ChangeDetectorRef,
    private sharedDataService: SharedDataService) { }
  ngOnInit(): void {
    this.warningSharedService.clearAllWarnings();
    // Listen to damage and repair form changes to send manhours to manhours component
    this.createWorkOrderForm.controls.damageAndRepairForm.valueChanges.subscribe((value) => {
      this.totalManhoursWithoutPrepTime = 0;
      this.manHoursThatQualifyForPrepTime = 0;
      this.isPrepTimeApplicable=false;
      value.repairLineItems?.forEach((repairLineItem) => {
        let manhours = +(repairLineItem?.manHoursPerPiece ?? 0);
        let pieces = +(repairLineItem?.pieces ?? 0);
        this.totalManhoursWithoutPrepTime = this.totalManhoursWithoutPrepTime + (manhours * pieces);
        let repair = this.repairs.find(x => x.repairCode === repairLineItem?.repairCode)
        if (!repair?.excludePreparationHours) {
          this.manHoursThatQualifyForPrepTime = this.manHoursThatQualifyForPrepTime + (manhours * pieces);
          this.isPrepTimeApplicable=true;
        }

      });
      this.totalManhoursWithoutPrepTime = utils.formatNumberToTwoDigitDecimal(this.totalManhoursWithoutPrepTime)
      console.log(this.totalManhoursWithoutPrepTime, "manhours");

    });

    this.createWorkOrderForm.controls.headerForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.updateMaintenanceOrderModel(false);
      });

    // listen to damageAndRepairForm and manhours form both to calculate total cost for the work order
    combineLatest([
      this.createWorkOrderForm.controls.damageAndRepairForm.valueChanges,
      this.createWorkOrderForm.controls.manHoursForm.valueChanges
    ])
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged(), debounceTime(500))
      .subscribe(() => {
        if (this.createWorkOrderForm.controls.damageAndRepairForm.valid && this.createWorkOrderForm.controls.manHoursForm.valid) {
          this.updateMaintenanceOrderModel(true);
          this.calculateTotalApiCallStatus = "IN_PROGRESS";
          this.workOrderService.calculateTotalCost(this.workorderId,this.maintenanceOrderModel).pipe(catchError(err => {
            this.calculateTotalApiCallStatus = "FAILED";
            throw err
          })).subscribe(cost => {
            this.cost = clone(cost);
            this.calculateTotalApiCallStatus = "COMPLETED_SUCCESFULLY";
          })
        }
        else {
          this.calculateTotalApiCallStatus = "IN_PROGRESS";
          this.updateMaintenanceOrderModel(false);
        }
      });
    if (this.workorderId) {
      this.sharedDataService.getWorkOrder().subscribe(workorder => {
        if (workorder) {
          this.workorder = workorder;
          this.isEditMode.set(true);
        } else {
          this.router.navigate(['/workorders', this.workorderId])
        }
      });

      this.workOrderService.getWorkOrderOrderExchangeRateInfoById(this.workorderId).subscribe(workorderCurrency => {
        this.workOrderCurrency = workorderCurrency;
      });
    }
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onSubmitClicked(scrollToElementOnError: HTMLHeadElement) {
    if (this.createWorkOrderForm.valid) {
      const repairLineItems = this.createWorkOrderForm.controls.damageAndRepairForm.controls.repairLineItems as FormArray<FormGroup<RepairLineItemForm>>;
      const maintananceOrderLineItems: MaintenanceOrderLineItem[] = this.mapRepairLineItemsToMaintenanceOrderLineItems(repairLineItems);

      let createWorkOrder = this.createWorkOrderForm.value;
      let attachment: AttachedImage[] = [];
      let maintenanceOrderManHours:MaintenanceOrderManHours[] = []
      if (createWorkOrder.attachmentForm && createWorkOrder.attachmentForm.files && createWorkOrder.attachmentForm.files.length > 0) {
        attachment = createWorkOrder.attachmentForm.files.map(file => { return { name: file.displayName ?? "", path: file.generatedName, extension: 'jpg', contentType: 'xxxx' } })
      }
      let reeferCondition = "";
      if (this.equipment.equipmentTypeCode === "REEF") {
        reeferCondition = "NOT_PROVIDED"
        if (createWorkOrder.headerForm?.boxOk) {
          reeferCondition = "BOX_OK"
        }
        if (createWorkOrder.headerForm?.macOk) {
          reeferCondition = "MAC_OK"
        }
      }
      let ordinaryHours: MaintenanceOrderManHours = {
        manHours: createWorkOrder.manHoursForm?.ordinaryHours,
        manHoursType: "REGULAR"
      };
      let overtimeHours: MaintenanceOrderManHours = {
        manHours: createWorkOrder.manHoursForm?.totalManHoursOvertime,
        manHoursType: "OVERTIME"
      }
      let doubletimeHours: MaintenanceOrderManHours = {
        manHours: createWorkOrder.manHoursForm?.totalManHoursDoubletime,
        manHoursType: "DOUBLETIME"
      }
      let miscHours: MaintenanceOrderManHours = {
        manHours: createWorkOrder.manHoursForm?.totalManHoursMisc,
        manHoursType: "MISCELLANEOUS"
      }
      maintenanceOrderManHours.push(ordinaryHours);
      maintenanceOrderManHours.push(overtimeHours);
      maintenanceOrderManHours.push(doubletimeHours);
      maintenanceOrderManHours.push(miscHours);

      this.maintananceOrderModelOnSubmit = {

        equipmentNumber: createWorkOrder.headerForm?.equipmentNumber,
        maintenanceShopCode: createWorkOrder.headerForm?.shopCode?.code,
        maintenanceRepairModeCode: createWorkOrder.headerForm?.mode?.code,
        vendorsReference: createWorkOrder.headerForm?.vendorRefNo,
        maintenanceOrderCause: createWorkOrder.headerForm?.cause?.code,
        damagedAt: createWorkOrder.headerForm?.thirdPartyPort,
        reeferContainerCondition: reeferCondition,
        attachedImages: attachment,
        maintenanceOrderManHours: maintenanceOrderManHours,
        maintenanceOrderLines: maintananceOrderLineItems,
        maintenanceOrderRemarks: this.getMaintenanceOrderRemark()

      };
      console.log(this.maintananceOrderModelOnSubmit);
      this.submitWorkOrder(scrollToElementOnError);
    };
  }
  getMaintenanceOrderRemark(): MaintenanceOrderRemark[] {
    let remarkText = this.createWorkOrderForm.controls.remarkForm.controls.remark.value
    let remark: MaintenanceOrderRemark[] = [];
    if (remarkText)
      remark.push({ maintenanceOrderRemarkText: remarkText, maintenanceOrderRemarkScope: MaintenanceOrderRemarkScope.ALL });
    return remark;
  }

  onCancelClicked() {
    if (this.createWorkOrderForm.dirty) {
      this.openCancelConfirmation = true;
    } else {
      this.router.navigate(['/workorders']);
    }
  }
  onCancelConfirmationClosed() {
    this.openCancelConfirmation = false;
  }
  onCancelRejection() {
    this.openCancelConfirmation = false;
  }
  onCancelConfirmation() {
    this.router.navigate(['/workorders'])
    this.openCancelConfirmation = false;
  }
  private submitWorkOrder(scrollTo: HTMLHeadElement) {
    this.submitLoader = true;
    this.maintananceOrderModelOnSubmit.maintenanceOrderNumber = this.workorderId;
    this.workOrderService.createOrUpdateWorkOrder(this.maintananceOrderModelOnSubmit, this.workorderId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response: MaintenanceOrderResponse) => {
          if (response) {
            console.log(response);
            this.createWorkOrderForm.reset();
            this.formSubmitErrors.set([]);
            if (this.workorderId) {
              this.toastr.showToastr("Work order re-submitted successfully!", 'success');
            } else {
              this.toastr.showToastr("Work order created successfully!", 'success');
            }
            this.submitLoader = false;
            this.router.navigate(['/workorders', response.maintenanceOrderNumber])
          }
        },
        error: (error: HttpErrorResponse) => {
          let errors: WorkOrderSubmitErrorModel = error.error;
          this.formSubmitErrors.set([]);
          if (error.status === 422) {
            errors?.violations?.every(error => this.formSubmitErrors.update(existingErrors => {
              existingErrors.push(error.message);
              return existingErrors
            }));
          } else {
            this.formSubmitErrors.set([errors.detail])
          }
          this.submitLoader = false;
          this.toastr.showToastr("Failed to create work order.", 'error');
          scrollTo.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
      });
  }



  private updateMaintenanceOrderModel(flagForUpdate: boolean) {
    if (flagForUpdate) {
      const repairLineItems = this.createWorkOrderForm.controls.damageAndRepairForm.controls.repairLineItems as FormArray<FormGroup<RepairLineItemForm>>;

      // Map to MaintenanceOrderLineItem array
      const maintenanceOrderLineItems: MaintenanceOrderLineItem[] = this.mapRepairLineItemsToMaintenanceOrderLineItems(repairLineItems);
      let createWorkOrder = this.createWorkOrderForm.value;
      let maintenanceOrderManHours: MaintenanceOrderManHours[] = [];
      let ordinaryHours: MaintenanceOrderManHours = {
        manHours: createWorkOrder.manHoursForm?.ordinaryHours,
        manHoursType: "REGULAR"
      };
      let overtimeHours: MaintenanceOrderManHours = {
        manHours: createWorkOrder.manHoursForm?.totalManHoursOvertime,
        manHoursType: "OVERTIME"
      }
      let doubletimeHours: MaintenanceOrderManHours = {
        manHours: createWorkOrder.manHoursForm?.totalManHoursDoubletime,
        manHoursType: "DOUBLETIME"
      }
      let miscHours: MaintenanceOrderManHours = {
        manHours: createWorkOrder.manHoursForm?.totalManHoursMisc,
        manHoursType: "MISCELLANEOUS"
      }
      maintenanceOrderManHours.push(ordinaryHours);
      maintenanceOrderManHours.push(overtimeHours);
      maintenanceOrderManHours.push(doubletimeHours);
      maintenanceOrderManHours.push(miscHours);
      // Create a new object with updated values
      this.maintenanceOrderModel = {
        ...this.maintenanceOrderModel,
        equipmentNumber: createWorkOrder.headerForm?.equipmentNumber,
        maintenanceOrderCause: createWorkOrder.headerForm?.cause?.code,
        vendorsReference: createWorkOrder.headerForm?.vendorRefNo,
        damagedAt: createWorkOrder.headerForm?.thirdPartyPort,
        maintenanceOrderManHours: maintenanceOrderManHours,
        maintenanceRepairModeCode: createWorkOrder.headerForm?.mode?.code,
        maintenanceShopCode: createWorkOrder.headerForm?.shopCode?.code ?? "",
        maintenanceOrderLines: maintenanceOrderLineItems
      };
      console.log(this.maintenanceOrderModel);
    }
    else {
      this.maintenanceOrderModel = {} as MaintenanceOrderCreateRequest;
    }
  }

  private mapRepairLineItemsToMaintenanceOrderLineItems(repairLineItems: FormArray<FormGroup<RepairLineItemForm>>): MaintenanceOrderLineItem[] {
    return repairLineItems?.controls.map((formGroup) => {
      const formValue = formGroup.value;
      let attachment: AttachedImage[] = [];
      if (formValue.attachments && formValue.attachments.files && formValue.attachments.files.length > 0) {
        attachment = formValue.attachments.files.map(file => { return { name: file.displayName ?? "", path: file.generatedName, extension: 'jpg', contentType: 'xxxx' } })
      }
      const maintenanceOrderLineItem: MaintenanceOrderLineItem = {
        equipmentDamageCode: formValue.damageCode ?? "",
        maintenanceOrderParts: formValue.parts?.map((part) => ({
          partNumber: part.partNumber ?? "",
          unitQuantity: part.pieces ?? 0,
          unitPrice: part.partCost ?? 0,
          replacedSerialNumbers: [part.oldPartSerialNumber ?? ""],
        })) || [],
        maintenanceRepairLocationCode: formValue.repairLocationCode ?? "",
        maintenanceTypeCode: formValue.repairCode ?? "",
        thirdPartyIndicator: this.tpiCodes.find(tpi => getTPIChar(tpi.code) === formValue.tpiCode)?.code ?? "",
        repairQuantity: formValue.pieces ?? 0,
        materialCostPerPiece: formValue.materialCostPerPiece ?? 0,
        manHoursPerPiece: formValue.manHoursPerPiece ?? 0,
        attachedImages: attachment
      };
      return maintenanceOrderLineItem;
    });
  }

  modeChanged(event: Mode) {
    this.mode = event;
    this.createWorkOrderForm.controls.remarkForm.reset()
  }
  shopChanged(event: Shop) {
    this.shop = event;
    this.createWorkOrderForm.controls.remarkForm.reset()
    let currency: Currency= {
      code:this.shop.currencyCode,
      name: this.shop.currencyName,
      exchangeRateFromUsd: this.shop.exchangeRateFromUsd??0,
      exchangeRateToUsd: this.shop.exchangeRateToUsd??0,
    }
    this.workOrderCurrency = currency;
  }
  headerFormValidChange(event: FormControlStatus) {
    this.headerFormValid.set(false);
    event === "VALID" ? this.headerFormValid.set(true) : this.headerFormValid.set(false);
  }
  equipmentChanged(event: Equipment) {

    this.equipment = clone(event)

    this.createWorkOrderForm.controls.remarkForm.reset()
  }

  closeWarning() {
    this.warningSharedService.clearAllWarnings();
  }



  async principalPropertyChanged(event: PrincipalProperty) {
    if (event.HeaderFormStatus === "VALID") {
      console.log("call started");
      this.gettingDataFromAPIForRepairLineItemsForm.set(true);
      this.changeDetector.detectChanges();
      await Promise.allSettled([
        this.getRepairs(event.Shop.code, event.Mode.code, event.Equipment, event.Mode.damageType),
        this.getContainerGrades(),
        this.getDamages(event.Mode.damageType),
        this.getTPIs(),
        this.getRepairLocations(event.Mode.damageType)
      ]);
      this.gettingDataFromAPIForRepairLineItemsForm.set(false);


      console.log(this.repairs);
      console.log("call ended");

    }
    console.log(event);
  }

  /**
   * Section get container grades
   */
  private getContainerGrades() {
    this.masterDataService.getContainerGrades()
      .pipe(catchError((error) => {
        this.containerGrades = []
        throw error;
      }), distinctUntilChanged())
      .subscribe(grades => {
        this.containerGrades = grades;
      })
  }
  /**
   * section to get tpi codes
   * 
   */
  private async getTPIs() {
    const tpis = await firstValueFrom(this.masterDataService.getRepairTpiCodes()
      .pipe(catchError((error) => {
        this.tpiCodes = []
        throw error;
      }), distinctUntilChanged()));
    this.tpiCodes = tpis;
  }
  /**
   * Section to get repir Loactions
   */
  private async getRepairLocations(damageType: DamageType) {
    const repairLocations = await firstValueFrom(this.masterDataService.getRepairLocations()
      .pipe(catchError((error) => {
        this.repairLocations = []
        throw error;
      }), distinctUntilChanged()));
    this.repairLocations = repairLocations.filter(x => x.damageType === damageType)
  }
  /**
   * Section to get damage codes
   */
  private async getDamages(damageType: DamageType) {
    const damages = await firstValueFrom(this.masterDataService.getDamageCodes(damageType)
      .pipe(catchError((error) => {
        this.damages = []
        throw error;
      }), distinctUntilChanged()));

    this.damages = damages

  }
  /**
   * Section to get repair codes
   * @param shopCode 
   * @param damageType 
   * @param equipment 
   * @returns 
   */
  private async getRepairs(shopCode: string, repairModeCode: string, equipment: Equipment, damageType: DamageType) {
    let repairs = await firstValueFrom(this.masterDataService.getRepairCodes(repairModeCode, shopCode,this.workOrderCurrency?.exchangeRateFromUsd??0)
      .pipe(catchError((error) => {
        this.repairs = []
        throw error;
      }), distinctUntilChanged()));
    repairs = repairs.filter(repair => repair.isActive && !repair.isSuspended);
    this.repairs = repairs; //this.filterRepairCodesOnEquipmentChange(repairs, equipment, damageType)
  }
  private filterRepairCodesOnEquipmentChange(repairs: Repair[], equipment: Equipment, damageType: DamageType): Repair[] {
    let filteredRepairs = repairs;
    if (equipment.equipmentTypeCode === 'REEF' && damageType === DamageType.NON_STRUCTURAL) {
      switch (equipment.reeferUnitManufacturerName?.toUpperCase()) {
        case "STARCOOL":
          filteredRepairs = repairs.filter(x => (x.code.startsWith("0") || x.code.startsWith("1")))
          break;
        case "DAIKIN":
          filteredRepairs = repairs.filter(x => (x.code.startsWith("0") || x.code.startsWith("2")))
          break;
        case "CARRIER":
          if (equipment.reeferUnitModel?.toUpperCase() === 'NATURALINE') {
            filteredRepairs = repairs.filter(x => (x.code.startsWith("0") || x.code.startsWith("4")))
          } else {
            filteredRepairs = repairs.filter(x => (x.code.startsWith("0") || x.code.startsWith("5")))
          }
          break;
        case "THERMO KING":
          let reeferModel = equipment.reeferUnitModel?.toUpperCase()
          if (reeferModel === 'TNE508' || reeferModel === 'CRR40' || reeferModel === 'MAGNUM') {
            filteredRepairs = repairs.filter(x => (x.code.startsWith("0") || x.code.startsWith("6")))
          } else {
            filteredRepairs = repairs.filter(x => (x.code.startsWith("0") || x.code.startsWith("8")))
          }
          break;
      }
    }
    return filteredRepairs;
  }
}
