import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthPolicies } from '../../../auth/auth-policies';
import { AdvanceFilterComponent } from '../advance-filter/advance-filter.component';
import { WorkOrderService } from '../../../services/work-order.service';
import { MaintenanceOrderStatusCount } from '../../../models/maintenance-order-status-count.model';
import { CommonModule } from '@angular/common';
import "@maersk-global/mds-components-core/mc-tag";
import "@maersk-global/mds-components-core/mc-modal";
import "@maersk-global/mds-components-core/mc-tooltip";
import { WorkOrderFilterForm } from '../../../form-model/work-order-filter.form.model';
import { MaintenanceOrderStatus } from '../../../models/maintenance-order-status.model';
import { Mode } from '../../../models/mode.model';
import { Shop } from '../../../models/shop.model';
import { debounceTime, distinctUntilChanged, ReplaySubject, takeUntil } from 'rxjs';
import { MaintenanceOrderFilter } from '../../../models/maintenance-order-filter.model';
import { WorkOrderSearchTextForm } from '../../../form-model/work-order-search-text.form.model';

type FilterValue = string | boolean | Mode[] | MaintenanceOrderStatus[] | Shop[];

@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, AdvanceFilterComponent],
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FiltersComponent {
  selectedOptions: MaintenanceOrderStatusCount[] = [];
  showAdvanceFilters: boolean = false;
  canUserCreateWO: boolean = false;
  taggedStatusListWithCount: MaintenanceOrderStatusCount[] = [];
  untaggedStatusListWithCount: MaintenanceOrderStatusCount[] = [];

  @Input({ required: true }) filterFormGroup!: FormGroup<{
    workOrderFilterForm: FormGroup<WorkOrderFilterForm>;
    workOrderSearchTextForm: FormGroup<WorkOrderSearchTextForm>;
  }>;

  savedFilter!: FormGroup<WorkOrderFilterForm>;
  taggedFilters: { key: string; value: FilterValue }[] = [];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private authPolicies: AuthPolicies, private workOrderService: WorkOrderService, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.workOrderService.getMaintenanceOrderStatusCount().pipe(takeUntil(this.destroyed$)).subscribe((statuscount) => {
      this.untaggedStatusListWithCount = statuscount;
    })
    if (this.authPolicies.canCreateWorkOrder) {
      this.canUserCreateWO = true;
    }
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges() {
    this.filterFormGroup.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        if (this.filterFormGroup.controls.workOrderFilterForm.controls.applyWOFilter.value) {
          this.showAdvanceFilters = false;
        }
        if(this.filterFormGroup.controls.workOrderSearchTextForm.controls.appliedWOSearchTextFilter.value){
          this.updateSelectedFiltersSection();
        }
      });
  }

  selectStatusFromQuickFilter(statusCount: MaintenanceOrderStatusCount): void {
    const newStatus: MaintenanceOrderStatus = {
      generatedCode: statusCount.maintenanceOrderGenertaedStatusCode,
      description: statusCount.maintenanceOrderStatusName
    };

    const currentStatuses = this.filterFormGroup.controls.workOrderFilterForm.controls.status.value || [];
    if (!currentStatuses.some(status => status.generatedCode === newStatus.generatedCode)) {
      const updatedStatuses = [...currentStatuses, newStatus];
      this.filterFormGroup.controls.workOrderFilterForm.controls.status.setValue(updatedStatuses);
      this.filterFormGroup.controls.workOrderFilterForm.controls.applyWOFilter.setValue(true);
      this.updateSelectedFiltersSection();
    }
  }


  unselectSpecificFilter(taggedFilter: { key: string; value: FilterValue }, itemNumber: number = -1): void {
    switch (taggedFilter.key) {
      case "Equipment Id":
        this.filterFormGroup.controls.workOrderFilterForm.controls.equipmentId.setValue(null);
        break;

      case "Mode":
        if (Array.isArray(taggedFilter.value) && itemNumber >= 0 && itemNumber < taggedFilter.value.length) {
          let taggedModes = taggedFilter.value as Mode[]
          const updatedModes: Mode[] = taggedModes.filter((_: Mode, index: number) => index !== itemNumber);
          this.filterFormGroup.controls.workOrderFilterForm.controls.mode.setValue(updatedModes.length ? updatedModes : null);
        }
        break;

      case "WO Number":
        this.filterFormGroup.controls.workOrderFilterForm.controls.woNumber.setValue(null);
        break;

      case "Status":
        if (Array.isArray(taggedFilter.value) && itemNumber >= 0 && itemNumber < taggedFilter.value.length) {
          let statuses = taggedFilter.value as MaintenanceOrderStatus[]
          const updatedStatuses: MaintenanceOrderStatus[] = statuses.filter((_: MaintenanceOrderStatus, index: number) => index !== itemNumber);
          this.filterFormGroup.controls.workOrderFilterForm.controls.status.setValue(updatedStatuses.length ? updatedStatuses : null);
        }
        break;

      case "From":
        this.filterFormGroup.controls.workOrderFilterForm.controls.fromDate.setValue(null);
        break;

      case "To":
        this.filterFormGroup.controls.workOrderFilterForm.controls.toDate.setValue(null);
        break;

      case "Shop ID":
        if (Array.isArray(taggedFilter.value) && itemNumber >= 0 && itemNumber < taggedFilter.value.length) {
          let shops = taggedFilter.value as Shop[]
          const updatedShops: Shop[] = shops.filter((_: Shop, index: number) => index !== itemNumber);
          this.filterFormGroup.controls.workOrderFilterForm.controls.shop.setValue(updatedShops.length ? updatedShops : null);
        }
        break;

      case "Show only flagged work orders":
        this.filterFormGroup.controls.workOrderFilterForm.controls.showFlaggedWO.setValue(null);
        break;

      default:
        break;
    }
    this.filterFormGroup.controls.workOrderFilterForm.controls.applyWOFilter.setValue(true);
    this.updateSelectedFiltersSection();
  }

  showAdvanceFiltersModal() {
    this.showAdvanceFilters = true;
    this.saveCurrentFilters();
  }

  closeAdvanceFiltersModal() {
    if (this.showAdvanceFilters) {
      this.showAdvanceFilters = false;
      this.filterFormGroup.controls.workOrderFilterForm.reset(this.savedFilter.value);
    }
    this.updateSelectedFiltersSection();
  }

  clearAllSelectedFilters() {
    this.filterFormGroup.reset();
    this.filterFormGroup.controls.workOrderFilterForm.controls.applyWOFilter.setValue(true);
    this.updateSelectedFiltersSection();
  }

  private saveCurrentFilters(): void {
    this.savedFilter = this.formBuilder.group({
      equipmentId: this.formBuilder.control<string | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.equipmentId.value),
      mode: this.formBuilder.control<Mode[] | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.mode.value),
      woNumber: this.formBuilder.control<string | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.woNumber.value),
      status: this.formBuilder.control<MaintenanceOrderStatus[] | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.status.value),
      fromDate: this.formBuilder.control<string | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.fromDate.value),
      toDate: this.formBuilder.control<string | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.toDate.value),
      shop: this.formBuilder.control<Shop[] | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.shop.value),
      showFlaggedWO: this.formBuilder.control<boolean | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.showFlaggedWO.value),
      applyWOFilter: this.formBuilder.control<boolean | null>(this.filterFormGroup.controls.workOrderFilterForm.controls.applyWOFilter.value),
    });
  }

  updateSelectedFiltersSection() {
    this.markAsTagged("Equipment Id", this.filterFormGroup.controls.workOrderFilterForm.controls.equipmentId.value);
    this.markAsTagged("Mode", this.filterFormGroup.controls.workOrderFilterForm.controls.mode.value);
    this.markAsTagged("WO Number", this.filterFormGroup.controls.workOrderFilterForm.controls.woNumber.value);
    this.markAsTagged("From", this.filterFormGroup.controls.workOrderFilterForm.controls.fromDate.value);
    this.markAsTagged("To", this.filterFormGroup.controls.workOrderFilterForm.controls.toDate.value);
    this.markAsTagged("Shop ID", this.filterFormGroup.controls.workOrderFilterForm.controls.shop.value);
    this.markAsTagged("Show only flagged work orders", this.filterFormGroup.controls.workOrderFilterForm.controls.showFlaggedWO.value);
    this.updateStatusCountAndTag();
  }

  private updateStatusCountAndTag() {
    const filters: MaintenanceOrderFilter = {};
    if (this.filterFormGroup.controls.workOrderFilterForm.controls.equipmentId.value) {
      filters.equipmentNumber = this.filterFormGroup.controls.workOrderFilterForm.controls.equipmentId.value;
    }
    if (this.filterFormGroup.controls.workOrderFilterForm.controls.mode.value && this.filterFormGroup.controls.workOrderFilterForm.controls.mode.value.length > 0) {
      filters.maintenanceRepairModes = this.filterFormGroup.controls.workOrderFilterForm.controls.mode.value.map(x => x.code).join(',');
    }
    if (this.filterFormGroup.controls.workOrderFilterForm.controls.woNumber.value) {
      filters.maintenanceOrderNumber = +this.filterFormGroup.controls.workOrderFilterForm.controls.woNumber.value;
    }
    if (this.filterFormGroup.controls.workOrderFilterForm.controls.fromDate.value) {
      filters.fromDate = this.filterFormGroup.controls.workOrderFilterForm.controls.fromDate.value;
    }
    if (this.filterFormGroup.controls.workOrderFilterForm.controls.toDate.value) {
      filters.toDate = this.filterFormGroup.controls.workOrderFilterForm.controls.toDate.value;
    }
    if (this.filterFormGroup.controls.workOrderFilterForm.controls.shop.value && this.filterFormGroup.controls.workOrderFilterForm.controls.shop.value.length > 0) {
      filters.maintenanceShopCodes = this.filterFormGroup.controls.workOrderFilterForm.controls.shop.value.map(x => x.code).join(',');
    }
    if (this.filterFormGroup.controls.workOrderSearchTextForm.controls.searchText.value) {
      filters.searchText = this.filterFormGroup.controls.workOrderSearchTextForm.controls.searchText.value;
    }
    if (this.filterFormGroup.controls.workOrderFilterForm.controls.showFlaggedWO.value) {
      filters.showFlaggedWO = this.filterFormGroup.controls.workOrderFilterForm.controls.showFlaggedWO.value;
    }
    this.workOrderService.getMaintenanceOrderStatusCount(filters).pipe(takeUntil(this.destroyed$)).subscribe({
      next: response => {
        const selectedStatuses = this.filterFormGroup.controls.workOrderFilterForm.controls.status.value || [];

        this.taggedStatusListWithCount = response.filter(status => selectedStatuses.some(selected => selected.generatedCode === status.maintenanceOrderGenertaedStatusCode)
        );
        this.untaggedStatusListWithCount = response.filter(status => !selectedStatuses.some(selected => selected.generatedCode === status.maintenanceOrderGenertaedStatusCode)
        );
        this.markAsTagged("Status", this.filterFormGroup.controls.workOrderFilterForm.controls.status.value);
      },
      error: err => {
      }
    }
    );
  }

  markAsTagged(key: string, newValue: FilterValue | null) {
    const currentValue = this.getFilterValue(key);
    if (currentValue !== newValue) {
      this.removeFilter(key);
      if (newValue !==false && newValue !== null && newValue !== '' && (!Array.isArray(newValue) || newValue.length > 0)) {
        this.taggedFilters.unshift({ key, value: newValue });
      }
    }
  }

  removeFilter(key: string) {
    this.taggedFilters = this.taggedFilters.filter(filter => filter.key !== key);
  }

  getFilterValue(key: string) {
    const filter = this.taggedFilters.find(filter => filter.key === key);
    return filter ? filter.value : null;
  }

  getTaggedFilterValue(taggedFilter: { key: string; value: FilterValue }, itemNumber: number = -1): FilterValue {
    switch (taggedFilter.key) {
      case "Equipment Id":
      case "WO Number":
      case "From":
      case "To":
        return taggedFilter.value;

      case "Mode":
        let modes = taggedFilter.value as Mode[]
        return modes[itemNumber].code;

      case "Status":
        let statuses = taggedFilter.value as MaintenanceOrderStatus[];
        let taggedStatus = this.taggedStatusListWithCount.find(x => x.maintenanceOrderGenertaedStatusCode == statuses[itemNumber].generatedCode);
        let count = taggedStatus ? taggedStatus.maintenanceOrderCount : 0;
        return `${statuses[itemNumber].description} (${count})`;

      case "Shop ID":
        let shops = taggedFilter.value as Shop[]
        return shops[itemNumber].code;

      case "Show only flagged work orders":
        let showFlaggedWO = taggedFilter.value as boolean;
        return showFlaggedWO ? "Yes" : "No";

      default:
        return '';
    }
  }

  getTaggedModes(): Mode[] {
    const modeTag = this.taggedFilters.find(taggedFilter => taggedFilter.key === "Mode");
    return modeTag && Array.isArray(modeTag.value) ? modeTag.value as Mode[] : [];
  }

  getTaggedStatuses(): MaintenanceOrderStatus[] {
    const statusTag = this.taggedFilters.find(taggedFilter => taggedFilter.key === "Status");
    return statusTag && Array.isArray(statusTag.value) ? statusTag.value as MaintenanceOrderStatus[] : [];
  }

  getTaggedShops(): Shop[] {
    const shopTag = this.taggedFilters.find(taggedFilter => taggedFilter.key === "Shop ID");
    return shopTag && Array.isArray(shopTag.value) ? shopTag.value as Shop[] : [];
  }


  isArrayType(value: FilterValue): boolean {
    return Array.isArray(value);
  }
}