import {
  Component,
  signal,
  CUSTOM_ELEMENTS_SCHEMA,
  input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import '@maersk-global/mds-components-core/mc-icon';
import '@maersk-global/mds-components-core/mc-button';
import '@maersk-global/mds-components-core/mc-label';
import '@maersk-global/mds-components-core/mc-toast';
import { UserMessages } from '../../../helper/user-messages';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { BlobService } from '../../../services/blob.service';
import { AemsFile } from '../../../models/aems-file.model';
import { AemsFileValidation } from '../validators/file-upload.validator';
import { ToastrService } from '../../../services/toastr-service';
import { DragDropDirective } from '../../../directives/drag-drop.directive';
import { BlobErrorType } from '../../../models/blob-error.model';

@Component({
  selector: 'app-file-input',
  standalone: true,
  imports: [CommonModule, FileViewerComponent,DragDropDirective],
  templateUrl: './file-input.component.html',
  styleUrl: './file-input.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInput {
  @Output() onFilesAttached=new EventEmitter<File[]>
  type = input.required<'full' | 'compact'>();
  @Input() files:AemsFile[] =[];

  constructor(
    private toastrService: ToastrService
  ) {}

   onFileChange(event: Event) {
    const control = event.target as HTMLInputElement;
    const incomingFiles = Array.from(control?.files ?? []);
    this.validateAndEmitFiles(incomingFiles);
  }



  onFileDroped(event:FileList){
  this.validateAndEmitFiles(Array.from(event));
  }
  
  private validateAndEmitFiles(files: File[]) {
    var sanatizedIncomingFiles = new AemsFileValidation(files,this.toastrService)
      .validateFileCount(this.files.length)
      .validateFileSize()
      .validateDuplicateFiles(this.files)
      .validateFileExtension()
      .get();
      this.onFilesAttached.emit(sanatizedIncomingFiles);
      console.log(this.files);
   
  }

  

}
