import { Component, CUSTOM_ELEMENTS_SCHEMA, input } from '@angular/core';
import "@maersk-global/mds-components-core/mc-icon";
import { RouterLink, RouterLinkActive } from '@angular/router';

type LeftHandNavMenuItem={
  key:string;
  title:string;
  url:string;
  icon:string;
  newExpDate:number;
}
@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [RouterLink,RouterLinkActive],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class SideMenuComponent {
  toggleMenu=input.required<boolean>();
}
