import { Component, Input, OnInit } from '@angular/core';
import { MaintenanceOrderResponse } from '../../../models/maintenance-order-response.model';
import { getDamageCodeDisplayText } from '../../../models/damage-cause.model';

@Component({
  selector: 'app-work-order-header',
  standalone: true,
  imports: [],
  templateUrl: './work-order-header.component.html',
  styleUrl: './work-order-header.component.scss'
})
export class WorkOrderHeaderComponent implements OnInit{
ngOnInit(): void {
  this.workOrderHeader.maintenanceOrderCauseDisplayText=getDamageCodeDisplayText(this.workOrderHeader.maintenanceOrderCause)
}
@Input() workOrderHeader!:MaintenanceOrderResponse;
}
