<form [formGroup]="headerForm">
    <div class="aems-card m-b-12">
        <div class="pure-g">
            <div class="pure-u-md-8-24 pure-u-lg-8-24 p-b-16 p-r-8">
                <mc-select ngDefaultControl formControlName="cause" label="Cause" placeholder="Select an option" fit="small">
                  @for (cause of causes(); track $index) {
                  <mc-option [value]="cause">
                    {{ cause.displayText }}
                  </mc-option>
                  }
                </mc-select>
              </div>
              @if(isThirdPartyDamage()){
                <div class="pure-u-md-8-24 pure-u-lg-8-24 p-b-16 p-r-8">
                  <mc-input ngDefaultControl label="3rd Party Port" formControlName="thirdPartyPort" placeholder="Type to search"
                    fit="small">
                    @if(headerForm.controls.thirdPartyPort.hasError('required')) {
                      <span slot="errormessage">Third party port is required</span>
                      }
                  </mc-input>
                </div>
                }
                <div class="pure-u-md-8-24 pure-u-lg-8-24 p-b-16 p-r-8">
                    <mc-input ngDefaultControl formControlName="vendorRefNo" label="Vendor Ref No. (Optional)"
                      placeholder="Vendor Ref No." fit="small">
                    </mc-input>
                  </div>
        </div>
        <div class="pure-g">
            <div class="pure-u-md-8-24 pure-u-lg-6-24 p-b-16 p-r-8 m-t-20">
                <div>{{workOrder?.equipmentNumber}}</div>
                <div class="mds-neutral--weak__text-color mds-text--x-small-normal" style="line-height: 10px;">Equipment Number</div>
              </div>
              <div class="pure-u-md-8-24 pure-u-lg-6-24 p-b-16 p-r-8 m-t-20">
                <div ngDefaultControl formControlName="shopCode">{{selectedShop().code}}</div>
                <div class="mds-neutral--weak__text-color mds-text--x-small-normal" style="line-height: 10px;">Shop Code</div>
              </div>
              <div ngDefaultControl formControlName="mode" class="pure-u-md-4-24 pure-u-lg-4-24 p-b-16 p-r-8 m-t-20">
                <div>{{selectedMode().code}}</div>
                <div class="mds-neutral--weak__text-color mds-text--x-small-normal" style="line-height: 10px;">Mode</div>
              </div>
            <div class="pure-u-md-4-24 pure-u-lg-4-24 p-b-16 p-r-8 m-t-20">
                <div>{{selectedShop().currencyName}}</div>
                <div class="mds-neutral--weak__text-color mds-text--x-small-normal" style="line-height: 10px;">Currency</div>
              </div>
        </div>
    </div>
</form>