import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { catchError, map, of, tap } from 'rxjs';
import { Equipment } from '../../../../models/equipment.model';
import { WarningSharedService } from '../../warning-shared-service';
import { Repair } from '../../../../models/repair.model';
import { AlarmCriticatlity } from '../../../../models/alarm-criticality.model';

/** This validation does not block work order creation, its gives a warning only
 * A live reefer can be repaired only when there are medium or high alarm against it.
 * Repair on live reefer can also be done in case of No alarm, very low alarm or low alram
 * If and only if the any single repair code in the work order belongs to an exceptional list of
 * repair codes
 */
export function nonEmptyReeferRepairWarning(
  index: number,
  equipment: Equipment | null,
  repairs: Repair[] | null,
  warningSharedService: WarningSharedService,
): ValidatorFn {
  return (control: AbstractControl) => {
    var repairCode = control.value as string;
    if (!repairCode)
      return null;
    if (!equipment)
      return null;
    if (equipment.isContainerEmpty || equipment.equipmentTypeCode!="REEF")
      return null;
    let validatorName = nonEmptyReeferRepairWarning.name + index;
    warningSharedService.deleteWarning(validatorName)
    if (!equipment.isContainerEmpty && HasNoOrLowAlarm(equipment)) {
      let selectedRepair = repairs?.find(x => (x.code == repairCode))
      if (selectedRepair && !selectedRepair?.isExceptionalRepair) {
        let warningObj = {
          validatorName: validatorName,
          message: 'You are creating this work order against a live reefer which does not have any high or medium alarm. It will be subject to enhanced scrutiny.'
        }
        warningSharedService.addWarning(warningObj);
      }
    }
    return null;
  }
}
function HasNoOrLowAlarm(equipment: Equipment): boolean {
  return !equipment?.alarms || equipment?.alarms.length == 0 ||
    (equipment.alarms.some(alarm => alarm.alarmCriticality == AlarmCriticatlity.Low
      || alarm.alarmCriticality == AlarmCriticatlity.VeryLow));
}

