import { WritableSignal } from '@angular/core';
import {
	AbstractControl,
	ValidationErrors,
	FormGroup,
	ValidatorFn,
} from '@angular/forms';
import { Mode } from '../../../../models/mode.model';
import { Equipment } from '../../../../models/equipment.model';
import { HeaderForm } from '../../../../form-model/cwo-header.form.model';
export function containerTypeWithModeValidator(
	errorControls: WritableSignal<string[]>
): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		let headerForm = control as FormGroup<HeaderForm>;
		const equipment = headerForm.controls.proxyEquipmentNumberForAsyncValidations.value;
		const mode = headerForm.controls.mode.value;
		errorControls.set([]);

		if (!equipment || !mode) {
			return null;
		}
		const validationResult =
			//validateStarCoolEquipment(mode, equipment) ||
			//validateMaerskLineOwnedInActiveEquipment(mode, equipment) ||
			null;

		if (validationResult) {
			errorControls.set(['equipmentNumber', 'mode']);
		}
		return validationResult;
	};
}

/**
 * Equipment manufactured by Star Cool are not allowed to get repaired with mode :
   [45 - CA PTI AND REPAIR ON CA REEFER , 46 - CA REPAIR ON LIVE CA REEFERS]
 * @param repairMode 
 * @param equipmentDetail 
 * @returns 
 */
/*Not applicable due to changes made in AEMS-912
function validateStarCoolEquipment(
	repairMode: Mode,
	equipmentDetail: Equipment
): ValidationErrors | null {
	if (
		(repairMode.code === '45' || repairMode.code === '46') &&
		equipmentDetail?.reeferUnitManufacturerName?.toUpperCase() === 'STARCOOL' &&
		equipmentDetail?.equipmentTypeParentCode === 'CONT'
	) {
		return {
			containerTypeWithModeValidator: {
				value: "Mode 45 or 46 not allowed for containers of type 'STARCOOL'",
			},
		};
	}
	return null;
}*/

/**
 * If ownership type is Own: Maersk Line owned equipment, and the equipment is marked as inactive/disposed, 
   in that case repair is not allowed if the repair mode belongs to:
   [22- NEUTRALISATION REEFER EQUIPMENT, 23 - NEUTRALISATION DRY EQUIPMENT,26 - MAERSK REUSE PARTS, 
	49 - RCD 1st time installation and demounting, 70 - Per OCL(Ocean Container Line) manual invoiced]
  
 * @param repairMode 
 * @param equipmentDetail 
 * @returns 
 */
/*function validateMaerskLineOwnedInActiveEquipment(
	repairMode: Mode,
	equipmentDetail: Equipment
): ValidationErrors | null {
	const ownershipTypeCode: string = 'OWN';
	const allowedRepairModes: string[] = ['22', '23','26','49', '70'];
	if (
		equipmentDetail.ownershipTypeCode === ownershipTypeCode &&
		!equipmentDetail.equipmentIsActive &&
		repairMode &&
		!allowedRepairModes.includes(repairMode.code)
	) {
		return {
			containerTypeWithModeValidator: { value: 'Equipment is disposed, repair not allowed' },
		};
	}
	return null;
}*/
