@if(type()==='full'){
<div class="container">
  @if(!readOnly){
  <app-file-input class="file-input" [files]="files" type="full"
    (onFilesAttached)="onFileAttached($event)"></app-file-input>
  }
  <app-file-preview [files]="files" (thumbnailClicked)="onThumbnailClicked($event)"></app-file-preview>
</div>
}  
@else{
<mc-modal heading="Upload File(s)" fit="small" dimension="medium" [open]="openUploadDialog"
  (closed)="closeUploadDialog()">
  <p style="font-size: 12px;">Upto 5 files can be uploaded.Only images,word document and pdf are supported. Maximum file
    size: 5Mbs</p>
  <app-file-input [files]="files" type="compact" (onFilesAttached)="onFileAttached($event)"></app-file-input>
  <app-file-preview class="m-t-8" [files]="files" (thumbnailClicked)="onThumbnailClicked($event)"></app-file-preview>
  <mc-button label="Done" slot="primaryAction" dialogaction="ok" appearance="primary" fit="small"></mc-button>
</mc-modal>

<mc-label for="image-button" fit="small" [label]="labelForCompact()">
</mc-label>
<div class="thumbnail-container">
  @if(!readOnly){
  <mc-button label="Button " variant="filled" appearance="neutral" fit="small" icon="paperclip" hiddenlabel
    padding="default" class="file-upload-button" (click)="onOpenAttachmentClicked()">
  </mc-button>
}
  <div>
    <app-file-preview [files]="files" type="compact" (thumbnailClicked)="onThumbnailClicked($event)"></app-file-preview>
  </div>
</div>
}
<app-file-viewer [readOnly]="readOnly" [openFileViewer]="openFileViewer" [file]="selectedFile" [disableNext]="disableNext"
  [disablePrevious]="disablePrevious" (fileViewerClosed)="onFileViewerClosed()" (fileDelete)="onDeleteFile($event)"
  (nextClicked)="onNextClicked()" (previousClicked)="onPreviousClicked()"></app-file-viewer>