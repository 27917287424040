import { CodeDescription } from "./code-description.model";

export interface DamageCause extends CodeDescription{
    displayText:string;
}

export function getDamageCodeDisplayText(code: "WEAR_AND_TEAR"|
    "HANDLING_DAMAGE"|
    "CONFIRMED_3RD_PARTY"|
    "UNCONFIRMED_3RD_PARTY"){
        switch(code){
            case "WEAR_AND_TEAR":return "1 Wear and Tear"
            case  "HANDLING_DAMAGE":return "2 Handling Damage"
            case "CONFIRMED_3RD_PARTY": return "3 Confirmed Third Party"
            case "UNCONFIRMED_3RD_PARTY": return "4 Unconfirmed Third Party"
        }
    }