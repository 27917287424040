export const environment =Object.freeze( {
    API_BASE_URL:'https://aems-order-api.dev.maersk-digital.net/api/',
    BLOB_BASE_URL:'https://aems-blob-manager-api.dev.maersk-digital.net/api/',
    MopRumApiKey: 'XiQgd5fAjehao3R38QBfCKOuh/KATKJ1LhiQ8FqonI1G61k0Du+pqmITdz7GDg==',
    OAUTH_CLIENT:'aems-web-forgerock-cdt',
    FORGEROCK_AUTHORITY:'https://accounts-cdt.maersk.com/thirdparty',
    REDIRECT_URL:'https://aems-cdt.maersk.com/oauth2/callback',
    POST_LOGOUT_URL:'https://aems-cdt.maersk.com/oauth2/callback',
    ENVIRONMENT:'cdt'
});
