import { Injectable } from "@angular/core";
import { Equipment } from "../../../models/equipment.model";
import { Mode } from "../../../models/mode.model";

/**
 * Service to filter repair modes depending on different paramters of an equipment
 * https://maersk-tools.atlassian.net/wiki/spaces/AAEMS/pages/183034545581/Filtering+modes+based+on+Equipment+properties
 */
@Injectable()
export class ModeFilterService {

    /**
     * @param equipment The equipment
     * @param modes List of all modes without any filtering
     * @returns List of valid modes
     */
    public filterModes(equipment: Equipment, modes: Mode[]): Mode[] {
        let validModes: string[] = [];
        if (equipment.isTaggedForRedelivery) {
            validModes = this.addRedeliveryModes(equipment);
        }
        else if (equipment.ownershipTypeCode === "OWN" && !equipment.equipmentIsActive) {
            validModes = this.addModesForInActiveEquipmentOwnedByMaersk(equipment);
        }
        else if(equipment.equipmentTypeParentCode==="GENS"){
            validModes=this.addGensetModes()
        }
        else {
            validModes = this.addModesBasedOnEquipmnentTypes(equipment);
        }
        return modes.filter(mode => validModes.includes(mode.code))
    }
    private addGensetModes(){
        return ["31","33","71"]
    }
    private addModesBasedOnEquipmnentTypes(equipment: Equipment): string[] {
        let modeCodes: string[] = [];
        switch (equipment.equipmentTypeCode) {
            case "REEF":
                if (equipment.constructionMaterialCode === "ALU") {
                    modeCodes.push("00")
                }
                if (["SST", "STL"].includes(equipment.constructionMaterialCode ?? "")) {
                    modeCodes.push("02")
                }
                if (this.isContainerControlledAtmosphere(equipment)) {
                    equipment.isContainerEmpty ? modeCodes.push("45") : modeCodes.push("46");
                } else {
                    if (equipment.isContainerEmpty) {
                        modeCodes.push("40")
                        modeCodes.push("41")
                        modeCodes.push("48")
                    } else {
                        modeCodes.push("43")
                    }
                }
                modeCodes.push("44");
                modeCodes.push("49");
                break;
            case "DRY":
                modeCodes.push("03");
                modeCodes.push("93");
                break;
            case "FLAT":
                modeCodes.push("04");
                break;
            case "OPEN":
                modeCodes.push("05");
                break;
            case "TANK":
                modeCodes.push("10");
                break;
        }
        return modeCodes;
    }
    private addModesForInActiveEquipmentOwnedByMaersk(equipment: Equipment): string[] {
        let modeCodes: string[] = [];
        modeCodes.push("70");
        switch (equipment.equipmentTypeCode) {
            case "REEF":
                modeCodes.push("22");
                modeCodes.push("26");
                break;
            case "DRY":
                modeCodes.push("23");
                break;
        }
        return modeCodes;
    }

    private addRedeliveryModes(equipment: Equipment): string[] {
        let modeCodes: string[] = [];
        switch (equipment.equipmentTypeCode) {
            case "REEF":
                modeCodes.push("82");
                break;
            case "DRY":
                modeCodes.push("83");
                break;
            case "FLAT":
                modeCodes.push("84");
                break;
            case "OPEN":
                modeCodes.push("85");
                break;
        }
        return modeCodes;
    }

    private isContainerControlledAtmosphere(equipment: Equipment): boolean {
        if (["CARP", "CASC", "CARF"].includes(equipment.equipmentSubTypeCode ?? "")) {
            return true;
        } else {
            return false;
        }
    }
}