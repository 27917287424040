import {
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  input,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  Validators,
  ReactiveFormsModule,
  FormControl,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import '@maersk-global/mds-components-core/mc-button';
import '@maersk-global/mds-components-core/mc-textarea';;
import { ToastrService } from '../../../services/toastr-service';
import { WorkOrderRemarkType } from '../../../models/workorder-remark-type.model';
import { CommonModule } from '@angular/common';
import { utils } from '../../../helper/utils';
import { WorkOrderService } from '../../../services/work-order.service';
import { RemarkForm } from '../../../form-model/cwo-remark.form.model';
import { MaintenanceOrderRemarkScope } from '../../../models/remark-scope.model';
import { debounceTime, distinctUntilChanged, forkJoin, ReplaySubject, takeUntil } from 'rxjs';
import { MaintenanceOrderRemark } from '../../../models/maintenance-order-remark.model';

@Component({
  selector: 'app-cwo-remarks',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './cwo-remarks.component.html',
  styleUrl: './cwo-remarks.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class CreateWorkOrderRemarksComponent implements OnInit {

  @Input() remarkForm!: FormGroup<RemarkForm>;
  @Input() workOrderMode: "create" | "view" = "create";
  @Input() workOrderRemarks: MaintenanceOrderRemark[] = [];
  @Input() workOrderId=''
  postingRemark=false;

  constructor(
    public workOrderService: WorkOrderService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.addControlsToFormGroup();
  }


  addControlsToFormGroup() {
    if (!this.remarkForm) {
      this.remarkForm = this.formBuilder.group({
        'remark': this.formBuilder.nonNullable.control<string>('',Validators.required)
      });
    } else {
      this.remarkForm.addControl('remark', this.formBuilder.nonNullable.control<string>(''));
    }
  }
  onSaveRemark() { 
    if(this.remarkForm.valid){
      this.postingRemark=true;
      let newRemark:MaintenanceOrderRemark={
        maintenanceOrderRemarkScope:MaintenanceOrderRemarkScope.ALL,
        maintenanceOrderRemarkText:this.remarkForm.controls.remark.value,
        createUserId:utils.getLoggedInUserName(),
        createTimestamp:new Date()
      }
      this.workOrderService.postRemarks(this.workOrderId,[newRemark]).subscribe(response=>{
        this.postingRemark=false;
        this.workOrderRemarks.push(newRemark)
        this.remarkForm.controls.remark.setValue("")
        console.log(response);
      })
    }
  }

  get sortedWorkOrderRemark(): MaintenanceOrderRemark[] {
    console.log(this.workOrderRemarks)
    return this.workOrderRemarks.sort((a, b) => (new Date(b.createTimestamp??0).getTime()) - (new Date(a.createTimestamp??0).getTime()));
  }
}
