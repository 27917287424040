@if(file){
<mc-modal [heading]="file.generatedName.length>50?file.generatedName.substring(0,50)+'...':file.generatedName" fit="small"
  dimension="large" height="98%" [open]="openFileViewer" (closed)="closeFileViewer()">
  @switch (file.fileType) {
  @case("application/vnd.openxmlformats-officedocument.wordprocessingml.document")
  {
  <object [type]="file.fileType"
    [data]="sanitizer.bypassSecurityTrustResourceUrl(file.fileUrl)"
    style="width:100%;height: 100%">

  </object>
  }
  @case("application/pdf"){
   
  <object [type]="file.fileType" [data]="sanitizer.bypassSecurityTrustResourceUrl(file.fileUrl)" style="width:100%;height: 100%"></object>

  }
  @default{
  <img style="width:100%;height: auto;" [src]="sanitizer.bypassSecurityTrustUrl(file.fileUrl)" alt="attachment-1">
  }
  }
  <div class="arrow left" (click)="onPreviousClicked()">
    <mc-icon size="24" icon="chevron-left">

    </mc-icon>
  </div>
  <div class="arrow right" (click)="onNextClicked()">
    <mc-icon size="24" icon="chevron-right">

    </mc-icon>
  </div>

  <mc-button label="Close" slot="primaryAction" dialogaction="ok" appearance="primary" fit="small"></mc-button>
  @if(!readOnly){
  <mc-button label="Delete" [loading]="deleting" slot="secondaryAction" variant="filled" appearance="neutral"
    (click)="onDeleteFileClick()" fit="small"></mc-button>
  }
  <mc-button fit="small" icon="tray-arrow-down" label="Download" variant="filled" appearance="neutral" slot="secondaryAction" [href]="file.fileUrl" [download]="file.generatedName">Download</mc-button>
</mc-modal>
}