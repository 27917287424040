import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnInit, input, signal } from '@angular/core';
import { TotalCost } from '../../../../../models/total-cost-model';
import { TableColumn } from '@maersk-global/mds-components-core/mc-table/types';
import { getTPIChar } from '../../../../../models/tpi.model';
import { CommonModule } from '@angular/common';
import { utils } from '../../../../../helper/utils';
@Component({
  selector: 'app-cwo-total-cost-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cwo-total-cost-details.component.html',
  styleUrl: './cwo-total-cost-details.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CreateWorkOrderTotalCostDetailsComponent implements OnInit{
  totalCostDetails = input.required<TotalCost>();
  materialCost: Array<TableColumn> =  [];
  laborCost: Array<TableColumn> = [];
  partCost: Array<TableColumn> = [];
  tax: Array<TableColumn> = [];
  total: Array<TableColumn> = [];
  materialCostTotal = signal<MaterialCostTotal[]>([]);
  laborCostTotal = signal<LaborCostTotal[]>([]);
  taxTotal = signal<Tax[]>([]);
  totalCost = signal<Total[]>([]);
  partCostTotal = signal<Part[]>([]);
  totalHours : number = 0;
  ngOnInit(): void {
    this.updateCostDetailsColumns();
    this.updateCostDetailsData();
  }
  private updateCostDetailsData() {
    let exchangeRate = this.totalCostDetails().shopExchangeRateToUsd;
    this.updateMaterialCost(exchangeRate);
    this.updateLaborCost(exchangeRate);
    this.updatePartCost(exchangeRate);
    this.updateTax(exchangeRate);
    this.updateTotal(exchangeRate);
  }
  private updatePartCost(exchangeRate: number) {
    const partCostData: Part[] = [];
    let partTotal : number = 0;
    this.totalCostDetails().lineItems.forEach((lineItem) => {
      lineItem.parts?.forEach((part)=>{
        partTotal += (part.finalPriceAfterDiscountAndMarkupPerUnit * part.quantity);
        partCostData.push({
          partNumber: part.partNumber,
          importTaxApplicable: "N",
          pcs: part.quantity,
          price: utils.formatNumberToTwoDigitDecimal(part.basePricePerUnit),
          priceTotal: utils.formatNumberToTwoDigitDecimal(part.finalPriceAfterDiscountAndMarkupPerUnit),
          mfrDiscount: utils.formatNumberToTwoDigitDecimal(part.manufacturerDiscount.percentage),
          shopDiscount: utils.formatNumberToTwoDigitDecimal(part.shopDiscount.percentage),
          total: utils.formatNumberToTwoDigitDecimal(part.finalPriceAfterDiscountAndMarkupPerUnit * part.quantity)
        });
      });
    });
    partCostData.push({
      id: 'part-id-usd',
      partNumber: "Parts total(USD)",
      total: utils.formatNumberToTwoDigitDecimal((partTotal * exchangeRate))
    });
    partCostData.push({
      id: 'part-id-local',
      partNumber: `Parts total(${this.totalCostDetails().shopCurrencyCode})`,
      total: utils.formatNumberToTwoDigitDecimal(partTotal)
    });
    this.partCostTotal.set([...partCostData]);
  }
  private updateTotal(exchangeRate: number) {
    const totalCostData: Total[] = [];
    totalCostData.push({
      taxCategoryTotal: "Total cost including tax/vat",
      shopCostUSD: utils.formatNumberToTwoDigitDecimal((this.totalCostDetails().totalOrderCostWithTax * exchangeRate)),
      shopCostLocal: utils.formatNumberToTwoDigitDecimal(this.totalCostDetails().totalOrderCostWithTax)
    });
    totalCostData.push({
      taxCategoryTotal: "Total cost excluding tax/vat",
      shopCostUSD: utils.formatNumberToTwoDigitDecimal((this.totalCostDetails().totalOrderCostWithoutTax* exchangeRate)),
      shopCostLocal: utils.formatNumberToTwoDigitDecimal(this.totalCostDetails().totalOrderCostWithoutTax)
    });
    this.totalCost.set([...totalCostData]);
  }
  private updateTax(exchangeRate: number) {
    const taxData: Tax[] = [];
    taxData.push({
      taxCategory: "Import Tax",
      taxPercentage: this.totalCostDetails().importTax.percentage,
      totalTaxUSD: utils.formatNumberToTwoDigitDecimal((this.totalCostDetails().importTax.value * exchangeRate)),
      totalTaxLocal: utils.formatNumberToTwoDigitDecimal(this.totalCostDetails().importTax.value)
    });
    taxData.push({
      taxCategory: "Sales tax - Parts",
      taxPercentage: this.totalCostDetails().partsSalesTax.percentage,
      totalTaxUSD: utils.formatNumberToTwoDigitDecimal((this.totalCostDetails().partsSalesTax.value * exchangeRate)),
      totalTaxLocal: utils.formatNumberToTwoDigitDecimal(this.totalCostDetails().partsSalesTax.value)
    });
    taxData.push({
      taxCategory: "Sales tax - Labor",
      taxPercentage: this.totalCostDetails().labourSalesTax.percentage,
      totalTaxUSD: utils.formatNumberToTwoDigitDecimal((this.totalCostDetails().labourSalesTax.value * exchangeRate)),
      totalTaxLocal: utils.formatNumberToTwoDigitDecimal(this.totalCostDetails().labourSalesTax.value)
    });
    this.taxTotal.set(taxData);
  }
  private updateLaborCost(exchangeRate : number) {
    const laborCostData: LaborCostTotal[] = [];

    this.totalHours = utils.formatNumberToTwoDigitDecimal(this.totalCostDetails().labourHourDistribution.ordinary.hours + this.totalCostDetails().labourHourDistribution.ot1.hours +
    this.totalCostDetails().labourHourDistribution.ot2.hours + this.totalCostDetails().labourHourDistribution.ot3.hours);

    let totalLaborCostExTax = utils.formatNumberToTwoDigitDecimal(this.totalCostDetails().labourHourDistribution.totalLabourHoursPriceBeforeTax);

    laborCostData.push({
      category : "Ordinary Hours",
      hours : this.totalCostDetails().labourHourDistribution.ordinary.hours,
      rateUSD : utils.formatNumberToTwoDigitDecimal((this.totalCostDetails().labourHourDistribution.ordinary.rate * exchangeRate)),
      rateLocal : utils.formatNumberToTwoDigitDecimal(this.totalCostDetails().labourHourDistribution.ordinary.rate),
      laborCostUSD : utils.formatNumberToTwoDigitDecimal((this.totalCostDetails().labourHourDistribution.ordinary.total * exchangeRate)),
      laborCostLocal : utils.formatNumberToTwoDigitDecimal(this.totalCostDetails().labourHourDistribution.ordinary.total)
    });
    laborCostData.push({
      category : "OT1",
      hours : this.totalCostDetails().labourHourDistribution.ot1.hours,
      rateUSD : utils.formatNumberToTwoDigitDecimal((this.totalCostDetails().labourHourDistribution.ot1.rate * exchangeRate)),
      rateLocal : utils.formatNumberToTwoDigitDecimal(this.totalCostDetails().labourHourDistribution.ot1.rate),
      laborCostUSD : utils.formatNumberToTwoDigitDecimal((this.totalCostDetails().labourHourDistribution.ot1.total * exchangeRate)),
      laborCostLocal : utils.formatNumberToTwoDigitDecimal(this.totalCostDetails().labourHourDistribution.ot1.total)
    });
    laborCostData.push({
      category : "OT2",
      hours : this.totalCostDetails().labourHourDistribution.ot2.hours,
      rateUSD : utils.formatNumberToTwoDigitDecimal((this.totalCostDetails().labourHourDistribution.ot2.rate * exchangeRate)),
      rateLocal : utils.formatNumberToTwoDigitDecimal(this.totalCostDetails().labourHourDistribution.ot2.rate),
      laborCostUSD : utils.formatNumberToTwoDigitDecimal((this.totalCostDetails().labourHourDistribution.ot2.total * exchangeRate)),
      laborCostLocal : utils.formatNumberToTwoDigitDecimal(this.totalCostDetails().labourHourDistribution.ot2.total)
    });
    laborCostData.push({
      category : "OT3",
      hours : this.totalCostDetails().labourHourDistribution.ot3.hours,
      rateUSD : utils.formatNumberToTwoDigitDecimal((this.totalCostDetails().labourHourDistribution.ot3.rate * exchangeRate)),
      rateLocal : utils.formatNumberToTwoDigitDecimal(this.totalCostDetails().labourHourDistribution.ot3.rate),
      laborCostUSD : utils.formatNumberToTwoDigitDecimal((this.totalCostDetails().labourHourDistribution.ot3.total * exchangeRate)),
      laborCostLocal : utils.formatNumberToTwoDigitDecimal(this.totalCostDetails().labourHourDistribution.ot3.total)
    });
    laborCostData.push({
      id: 'labor-cost-ex-tax',
      category: 'Labor cost excluding tax',
      laborCostUSD: utils.formatNumberToTwoDigitDecimal((totalLaborCostExTax * exchangeRate)),
      laborCostLocal: utils.formatNumberToTwoDigitDecimal(totalLaborCostExTax)
    });
    this.laborCostTotal.set([...laborCostData]);
  }
  private updateMaterialCost(exchangeRate : number) {
    const materialCostData: MaterialCostTotal[] = [];
    this.totalCostDetails().lineItems.forEach((lineItem) =>{
      if(lineItem.repairCode != ""){
        materialCostData.push({
          repairCode : lineItem.repairCode,
          type : getTPIChar(lineItem.thirdPartyIndicator),
          isoCurrencyCodeUSD : utils.formatNumberToTwoDigitDecimal((lineItem.totalMaterialCost * exchangeRate)),
          isoCurrencyCodeLocal : utils.formatNumberToTwoDigitDecimal(lineItem.totalMaterialCost),
        })
      }
    });
    this.materialCostTotal.set([...materialCostData]);
  }
  private updateCostDetailsColumns() {
    this.materialCost = [
      { id: 'repairCode', label: 'Repair Code' },
      { id: 'type', label: 'Type' },
      { id: 'isoCurrencyCodeUSD', label: `Material Cost(USD)`, align: 'right' },
      { id: 'isoCurrencyCodeLocal', label: `Material Cost(${this.totalCostDetails().shopCurrencyCode})`, align: 'right' }
    ];
    this.laborCost = [
      { id: 'category', label: 'Category' },
      { id: 'hours', label: 'Hours', align: 'right' },
      { id: 'rateUSD', label: `Rate(USD)`, align: 'right' },
      { id: 'rateLocal', label: `Rate(${this.totalCostDetails().shopCurrencyCode})`, align: 'right' },
      { id: 'laborCostUSD', label: `Labor Cost(USD)`, align: 'right' },
      { id: 'laborCostLocal', label: `Labor Cost(${this.totalCostDetails().shopCurrencyCode})`, align: 'right' }
    ];
    this.partCost = [
      { id: 'partNumber', label: 'Part Number' },
      { id: 'importTaxApplicable', label: 'Import Tax Applicable', align: 'right'},
      { id: 'pcs', label: `Pcs`, align: 'right' },
      { id: 'price', label: `Price`, align: 'right' },
      { id: 'priceTotal', label: `Price Total`, align: 'right' },
      { id: 'mfrDiscount', label: `MFR Discount`, align: 'right' },
      { id: 'shopDiscount', label: `Shop Discount`, align: 'right' },
      { id: 'total', label: `Total`, align: 'right' }
    ];
    this.tax = [
      { id: 'taxCategory', label: 'Tax Category' },
      { id: 'taxPercentage', label: 'Tax %', align: 'right'},
      { id: 'totalTaxUSD', label: `Total(USD)`, align: 'right' },
      { id: 'totalTaxLocal', label: `Total(${this.totalCostDetails().shopCurrencyCode})`, align: 'right' },
    ];
    this.total = [
      { id: 'taxCategoryTotal', label: 'Tax Category' },
      { id: 'shopCostUSD', label: 'Shop Cost(USD)', align: 'right'},
      { id: 'shopCostLocal', label: `Shop Cost(${this.totalCostDetails().shopCurrencyCode})`, align: 'right' },
    ];
  }
  hasParts(): boolean {
    return this.totalCostDetails().lineItems.some(item => item.parts && item.parts.length > 0);
  }
}
interface MaterialCostTotal {
  repairCode: string;
  type?: string;
  isoCurrencyCodeUSD?: number;
  isoCurrencyCodeLocal?: number;
}
interface LaborCostTotal{
  id? : 'labor-cost-ex-tax';
  category : string;
  hours?: number;
  rateUSD?: number;
  rateLocal?: number;
  laborCostUSD?: number;
  laborCostLocal?: number;
}
interface Tax{
  taxCategory: string;
  taxPercentage?: number;
  totalTaxUSD?: number;
  totalTaxLocal?: number;
}
interface Total{
  taxCategoryTotal: string;
  shopCostUSD?: number;
  shopCostLocal?: number;
}
interface Part{
  id?: string;
  partNumber?: string;
  importTaxApplicable?: string;
  pcs?: number;
  price?: number;
  priceTotal?: number;
  mfrDiscount?: number;
  shopDiscount?: number;
  total?: number;
}