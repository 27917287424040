import { Component, Input, CUSTOM_ELEMENTS_SCHEMA, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import "@maersk-global/mds-components-core/mc-tag";
import { WorkOrderHeaderComponent } from '../../components/view-work-order/work-order-header/work-order-header.component';
import { WorkOrderService } from '../../services/work-order.service';
import { Equipment } from '../../models/equipment.model';
import { CreateWorkOrerContainerDetailsComponent } from '../../components/create-work-order/container-details/cwo-container-details.component';
import { AemsFile } from '../../models/aems-file.model';
import { BlobService } from '../../services/blob.service';
import { FileUploadComponent } from '../../components/aems-file-upload/file-upload.component';
import { ListRepairLineItemsComponent } from '../../components/view-work-order/list-repair-line-items/list-repair-line-items.component';
import { ReplaySubject, catchError, firstValueFrom, takeUntil, throwError, timer } from 'rxjs';
import { MaintenanceOrderRemark } from '../../models/maintenance-order-remark.model';
import { CreateWorkOrderRemarksComponent } from '../../components/create-work-order/remarks/cwo-remarks.component';
import { ToastrService } from '../../services/toastr-service';
import { MaintenanceOrderResponse } from '../../models/maintenance-order-response.model';
import { TotalCost } from '../../models/total-cost-model';
import { CreateWorkOrderTotalCostComponent } from "../../components/create-work-order/total-cost/cwo-total-cost/cwo-total-cost.component";
import { AttachedImage } from '../../models/maintenance-order-attached-image';
import { SharedDataService } from '../../services/shared-data.service';
import { utils } from '../../helper/utils';
import { APICallStatus } from '../../models/api-call-status.mode';
import { LoaderComponent } from "../../components/loader/loader.component";
import { AuditLogComponent } from '../../components/view-work-order/audit-log/audit-log.component';
import { AuthPolicies } from '../../auth/auth-policies';
import { MaintenanceOrderStatusCode } from '../../models/maintenance-order-status.model';
@Component({
  selector: 'app-view-work-order',
  standalone: true,
  imports: [WorkOrderHeaderComponent,
    CommonModule,
    RouterModule,
    CreateWorkOrerContainerDetailsComponent,
    FileUploadComponent,
    ListRepairLineItemsComponent,
    CreateWorkOrderRemarksComponent, CreateWorkOrderTotalCostComponent, LoaderComponent, AuditLogComponent],
  templateUrl: './view-work-order.component.html',
  styleUrl: './view-work-order.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ViewWorkOrderComponent implements OnInit {
  @Input() id!: string;
  openDeleteWorkOrderConfirmation: boolean = false;
  equipment: Equipment | null = null;
  workOrder: MaintenanceOrderResponse | null = null;
  remarks: MaintenanceOrderRemark[] = []
  totalCost!: TotalCost;
  blobFilesResolved = signal(false);
  workOrderStatus = signal("");
  viewDataApiCalls:APICallStatus="NOT_STARTED"
  deleteApiCallStatus: APICallStatus = "NOT_STARTED";
  markAsCompleteAPICallStatus: APICallStatus = "NOT_STARTED";
  markAsTtlApiCallStatus:APICallStatus="NOT_STARTED";
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  openAuditLog: boolean = false;
  constructor(private workOrderService: WorkOrderService,
    private blobService: BlobService,
    private toastrService: ToastrService,
    private sharedDataService: SharedDataService,
    public authPolicy:AuthPolicies,
    private router: Router) {

  }
  private fileType(fileName: string): string {
    switch (fileName.split('.')[1]) {
      case ("pdf"): return "application/pdf"
      case ("docx"): return "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      default: return "application/image"
    }
  }
  async ngOnInit() {
    this.viewDataApiCalls="IN_PROGRESS";
    this.workOrder = await firstValueFrom(this.workOrderService.getWorkOrderById(this.id)
      .pipe(takeUntil(this.destroyed$), catchError(err => { 
        console.error("404 work order not found");
        throw err
       })));
    if (this.workOrder) {
      this.workOrderStatus.set(utils.getMaintenanceOrderStatusText(this.workOrder.maintenanceOrderStatusCode).description)
      this.workOrder.blobResolvedFiles = await this.getBlobUrl(this.workOrder.maintenanceShopCode, this.workOrder.attachedImages)
      this.workOrder.maintenanceOrderLines.forEach(async item => {
        item.blobResolvedFiles = await this.getBlobUrl(this.workOrder?.maintenanceShopCode, item.attachedImages);
      })
      this.blobFilesResolved.set(true);
    }else{
      this.toastrService.showToastr('Work order does not exist',"warning");
    }



    this.equipment= await firstValueFrom(this.workOrderService.getEquipmentDetails(this.id)
      .pipe(takeUntil(this.destroyed$),
        catchError(err => {
          this.toastrService.showToastr("Unable to get equipment details", "error");
          throw err;
        })));

    this.totalCost=await firstValueFrom(this.workOrderService.getTotalCost(this.id)
      .pipe(takeUntil(this.destroyed$)));
     
    this.remarks=await firstValueFrom(this.workOrderService.getRemarks(this.id)
      .pipe(takeUntil(this.destroyed$)));

    this.viewDataApiCalls="COMPLETED_SUCCESFULLY";
      
  }

  onAuditLogClosed() {
    this.openAuditLog = false;
  }

  onAuditLogClicked() {
    this.openAuditLog = true;
  }

  onEditClicked() {
    this.sharedDataService.setWorkOrder(this.workOrder)
    this.sharedDataService.setEquipment(this.equipment);
    this.router.navigate(['/workorders/edit', this.id])
  }

  onDeleteClicked() {
    this.openDeleteWorkOrderConfirmation = true;
  }
  onDeleteWorkOrderConfirmed() {

    this.deleteApiCallStatus = "IN_PROGRESS";
    this.workOrderService.deleteWorkOrder(this.id).pipe(catchError((err) => {
      console.log("error");
      this.deleteApiCallStatus = "FAILED";
      throw err;
    })).subscribe(x => {
      console.log("confrmed");
      this.deleteApiCallStatus = "COMPLETED_SUCCESFULLY"
      timer(2000).subscribe(x => {
        this.router.navigate(['/']);
      })
    })
  }
  onDeleteWorkOrderConfirmationClosed() {
    this.openDeleteWorkOrderConfirmation = false;
  }
  onBackClicked() {
    this.router.navigate(['/workorders'])
  }
  onMarkCompleteClicked() {
    this.markAsCompleteAPICallStatus = "IN_PROGRESS"
    this.workOrderService.completeWorkOrder(this.id).pipe(catchError(err => {
      this.markAsCompleteAPICallStatus = "FAILED"
      this.toastrService.showToastr("Workorder cannnot be completed", "error");
      throw err;
    })).subscribe(() => {
      this.markAsCompleteAPICallStatus = "COMPLETED_SUCCESFULLY"
      this.toastrService.showToastr("Workorder marked as complete", "success");
      this.workOrderStatus.set(utils.getMaintenanceOrderStatusText(MaintenanceOrderStatusCode.RepairCompleted).description)
    })
  }
  onMarkTTLClicked() {
    this.markAsTtlApiCallStatus = "IN_PROGRESS"
    this.workOrderService.markAsTTl(this.id).pipe(catchError(err => {
      this.markAsTtlApiCallStatus = "FAILED"
      this.toastrService.showToastr("Error while marking the work order as TTL", "error");
      throw err;
    })).subscribe(() => {
      this.markAsTtlApiCallStatus = "COMPLETED_SUCCESFULLY"
      this.toastrService.showToastr("Workorder marked as TTL", "success");
      this.workOrderStatus.set(utils.getMaintenanceOrderStatusText(MaintenanceOrderStatusCode.DeclaredAsTTL).description)
    })
  }
  private async getBlobUrl(shopCode: string | undefined, attachedImages: AttachedImage[]): Promise<AemsFile[]> {
    let temp: AemsFile[] = [];
    if (attachedImages.length > 0) {
      for (let i = 0; i < attachedImages.length; i++) {
        let url = await firstValueFrom(this.blobService.getResourceUrl(shopCode, attachedImages[i].path ?? "")
          .pipe(takeUntil(this.destroyed$)));
        temp.push({
          fileUrl: url,
          shopCode: shopCode,
          uploadedToBlob: true,
          generatedName: attachedImages[i].path ?? "",
          fileType: this.fileType(attachedImages[i].path ?? "")
        });
      }
    }
    return temp;
  }
  ngOnDestroy(): void {

    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
