<header>
    <div id="nav-title">
        <div>
            @if(isSideNavOpen){
            <mc-icon (click)="onHamburgerClicked()" class="collapsed-menu" icon="times" size="24"></mc-icon>
            }@else{
            <mc-icon (click)="onHamburgerClicked()" class="collapsed-menu" icon="bars-horizontal" size="24"></mc-icon>
            }
        </div>
        <img class="maersk-logo" alt="maersk logo" title="maersk logo" src="assets/images/star.svg">
        
        <span class="product-title">Advanced Equipment Maintenance System</span>
    </div>
    <div class="icon-container">
        <ul>
            <li>
                <mc-tooltip>
                    <mc-button label="Faq" hiddenlabel variant="plain" icon="question-circle"
                        slot="trigger"></mc-button>
                    <span>Open FAQs</span>
                </mc-tooltip>
            </li>
            <li>
                <mc-tooltip>
                    <mc-button label="Notifications" hiddenlabel variant="plain" icon="bell"
                        slot="trigger"></mc-button>
                    <span>Notifications</span>
                </mc-tooltip>
            </li>
            <li>
                <mc-tooltip>
                    <mc-button label="Profile" hiddenlabel variant="plain" icon="user-circle"
                         slot="trigger"></mc-button>
                    <span>My Profile</span>
                </mc-tooltip>
            </li>
            <li>
                <mc-tooltip>
                    <mc-button label="Change theme" hiddenlabel variant="plain" icon="moon"
                        (click)="onToggleThemeClicked()" slot="trigger"></mc-button>
                    <span>Toggle theme</span>
                </mc-tooltip>
            </li>
            <li>
                <mc-tooltip>
                    <mc-button label="Logout" hiddenlabel variant="plain" icon="door-arrow-right"
                    (click)="onLogoutClicked()" slot="trigger"></mc-button>
                    <span>Logout</span>
                </mc-tooltip>
            </li>
        </ul>
    </div>
    <div class="overlay" (click)="onHamburgerClicked()" [class.overlay-visible]="isSideNavOpen">

    </div>
</header>