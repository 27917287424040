import { Injectable } from '@angular/core';
import {
  BlobServiceClient,
  BlobUploadCommonResponse
} from '@azure/storage-blob';
import { BehaviorSubject, Observable, firstValueFrom, map, of, timer } from 'rxjs';
import { AemsFile } from '../models/aems-file.model';
import { LocalStorageWithExpiry } from '../helper/local-storage-with-expiry';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { utils } from '../helper/utils';
import { BlobErrorType } from '../models/blob-error.model';
import { BlobSas } from '../models/blob-sas.model';
import { resetFakeAsyncZone } from '@angular/core/testing';
@Injectable({
  providedIn: 'root',
})
export class BlobService {
  private readonly blockSize = 500 * 1000;
  constructor(private httpclient: HttpClient) {

  }

  async uploadFiles(
    files: AemsFile[],
    shopCode: string
  ): Promise<BlobUploadCommonResponse[]> {
    let LOCALSTORAGE_KEY = `upload-sas-${shopCode}`;
    let sasObject = LocalStorageWithExpiry.getItem<BlobSas>(LOCALSTORAGE_KEY);
    if (!sasObject) {
      let response = await this.httpclient
        .get<BlobSas>(`${environment.BLOB_BASE_URL}sas-token/upload/${shopCode}`)
        .toPromise()
        .catch((e) => {
          console.log(e);
          return null;
        });
      if (response) {
        LocalStorageWithExpiry.setItem<BlobSas>(LOCALSTORAGE_KEY, response, response.expiresOn);
        sasObject = response;
      }
    }
    if (sasObject?.token) {
      const blobServiceClient = new BlobServiceClient(
        `https://${sasObject.storageAccount}.blob.core.windows.net?${sasObject?.token.split('?')[1]}`
      );
      const blobContainerClient = blobServiceClient.getContainerClient(
        sasObject.container
      );
      // block size in bytes. Defines a block size of 500KB
      let uploadList: Promise<BlobUploadCommonResponse>[] = [];
      files.forEach(async (file) => {
        if (file.file) {
          const fileName = file.generatedName;
          const blockBlobClient = blobContainerClient.getBlockBlobClient(fileName);
          console.time('upload');
          let individualRequest = blockBlobClient.uploadData(file.file, {
            blockSize: this.blockSize,

            onProgress: (ev) => {
              file.progress$?.next((ev.loadedBytes / (file.file?.size ?? 1)) * 100);
            },
            blobHTTPHeaders: { blobContentType: file.file.type, blobContentDisposition: "inline" },
            maxSingleShotSize: this.blockSize,

            // Todo add tags
          });
          uploadList.push(individualRequest);
          console.timeEnd('upload');
        }
      });

      return await Promise.all(uploadList);
    } else {
      console.log("No sas token found", sasObject);
      return Promise.reject(BlobErrorType.NO_SAS_URL);
    }
  }

  // Do not actually delete just mock some delay, actual deletion to be done via backend when the form is submitted
  deleteFile(fileName: string, shopCode: string | undefined): Observable<any> {
    return timer(500);
    return this.httpclient.get(`${environment.BLOB_BASE_URL}manage/${shopCode}/delete/${fileName}`);
  }
  getResourceUrl(shopCode: string | undefined, fileName: string): Observable<string> {
    let LOCALSTORAGE_KEY = `download-sas-${shopCode}`;
    let sasObject = LocalStorageWithExpiry.getItem<BlobSas>(LOCALSTORAGE_KEY);
    if (!sasObject) {
      return this.httpclient
        .get<BlobSas>(`${environment.BLOB_BASE_URL}sas-token/download/${shopCode}`).pipe(
          map(sasObject => {
            LocalStorageWithExpiry.setItem<BlobSas>(LOCALSTORAGE_KEY, sasObject, sasObject.expiresOn);
            return `https://${sasObject.storageAccount}.blob.core.windows.net/${sasObject.container}/${fileName}?${sasObject?.token.split('?')[1]}`
          }))
    } else {
      return of(`https://${sasObject.storageAccount}.blob.core.windows.net/${sasObject.container}/${fileName}?${sasObject?.token.split('?')[1]}`)
    }
    //return this.httpclient.get<BlobSas>(`${environment.BLOB_BASE_URL}sas-token/download/${shopCode}`)
  }
}
