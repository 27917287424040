import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstWord',
  standalone: true
})
export class FirstWordPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let initials = value.split(' ')[0];
    return initials;
  }

}
