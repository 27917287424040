@if (equipment()) {

<div class="aems-card m-b-12">
  <div class="pure-g">
    <div class="pure-u-24-24 m-b-12">
      <p class="mds-content"><strong>Container Details</strong></p>
      <hr />
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="equipmentType">{{equipment().equipmentTypeCode??'-'}}/{{equipment().equipmentTypeParentCode??'-'}}</div>
      <div class="cont-details-label">Type</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="infleetDate">{{(equipment().infleetDate | date)??'-'}}</div>
      <div class="cont-details-label">In Service</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="productionYear">{{(equipment().manufacturingDateTime| date)??'-'}}</div>
      <div class="cont-details-label">Production Year</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="equipmentProfile">{{equipment().equipmentProfile??'-'}}</div>
      <div class="cont-details-label">Profile</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="equipmentSize">{{equipment().equipmentSize??'-'}}</div>
      <div class="cont-details-label">Size</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      @if(equipment().ptiStatus){
      <div class="cont-details-value" data-test="ptiStatus">{{(equipment().ptiStatus?.date | date)??'-'}}</div>
      }
      @else{
      <div style="display: flex;align-items: center;" class="cont-details-value" data-test="ptiStatus">Not Found<mc-icon size="20" class="warning-icon" icon="exclamation-circle-solid"></mc-icon></div>
      }
      <div class="cont-details-label">PTI Date</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="damageCode">{{equipment().damageCode??'-'}}</div>
      <div class="cont-details-label">Damage</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="gradeCode">{{equipment().gradeCode??'-'}}</div>
      <div class="cont-details-label">Grade Code</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="constructionMaterialCode">{{equipment().constructionMaterialCode??'-'}}</div>
      <div class="cont-details-label">Material</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="shoplocation">{{shopLocationCode()??'-'}}</div>
      <div class="cont-details-label">Shop Location</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="currentMove">{{equipment().currentMove??'-'}}</div>
      <div class="cont-details-label">CurrMove</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="forSale">{{ equipment().isTaggedForSelling === true ? 'Y' : equipment().isTaggedForSelling === false ? 'N' : '-' }}</div>
      <div class="cont-details-label">For Sale</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      @if(equipment().ptiStatus){
      <div class="cont-details-value" data-test="ptiStatusDesc">{{equipment().ptiStatus?.description??'-'}}</div>
      }
      @else{
      <div style="display: flex;align-items: center;" class="cont-details-value" data-test="ptiStatusDesc">Not Found<mc-icon size="20" class="warning-icon" icon="exclamation-circle-solid"></mc-icon></div>
      }
      <div class="cont-details-label">
        Container Status
      </div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="containerStatus">{{equipment().ownershipTypeCode??'-'}}</div>
      <div class="cont-details-label">Owner</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="containerStatus">{{ equipment().isTaggedForRedelivery === true ? 'Y' : equipment().isTaggedForRedelivery === false ? 'N' : '-' }}</div>
      <div class="cont-details-label">On Global Hunt</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="ContainerGateInDate">{{(equipment().infleetDate | date)??'-'}}</div>
      <div class="cont-details-label">Gate In Date</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="presentLocation">{{equipment().presentLocation??'-'}}</div>
      <div class="cont-details-label">Present Location</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="isAtOffHireLocation">{{ equipment().isAtOffHireLocation === true ? 'Y' : equipment().isAtOffHireLocation === false ? 'N' : '-' }}</div>
      <div class="cont-details-label">At Off Hire Loc</div>
    </div>
    <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
      <div class="cont-details-value" data-test="isContainerEmpty">{{ equipment().isContainerEmpty === true ? 'Y' : equipment().isContainerEmpty === false ? 'N' : '-' }}</div>
      <div class="cont-details-label">Empty</div>
    </div>
  </div>
</div>
}
