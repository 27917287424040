import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
interface ToastTriggers {
  warning: ElementRef<HTMLElement>;
  success: ElementRef<HTMLElement>;
  error: ElementRef<HTMLElement>;
}
interface ToastMessage {
  message: string;
  type: string;
}
@Injectable({
  providedIn: 'root'
})
export class ToastrService {
  private toastTriggers!: ToastTriggers;
  private toastMessage = new BehaviorSubject<ToastMessage>({ message: '', type: '' });

  public init(toastTriggers: ToastTriggers) {
    this.toastTriggers = toastTriggers;
  }

  public showToastr(message: string, type: keyof ToastTriggers) {
    if (this.toastTriggers[type]) {
      this.toastMessage.next({ message, type });
      setTimeout(() => this.toastTriggers[type].nativeElement.click(), 0); // Trigger the toast
    } else {
      console.error(`No toast trigger found for type: ${type}`);
    }
  }

  public getToastMessage(): BehaviorSubject<ToastMessage> {
    return this.toastMessage;
  }
  }
