import { Injectable } from "@angular/core";
import { User } from "../models/user.model";
import { AuthHelper } from "./auth-helper";
import { UserAccessType } from "../models/user-access-type.model";

@Injectable({ providedIn: 'root' })
export class AuthPolicies {
    loggedinUser: User | null = AuthHelper.user;
    get canCreateWorkOrder(): boolean {
        console.log(this.loggedinUser)
        if (this.loggedinUser) {
            return this.loggedinUser.roles.some(role => role.toLowerCase() === 'shop' || role.toLowerCase() === 'admin')
        }
        else {
            return false;
        }
    }
    get canMarkWorkOrderAsTTL():boolean{
        if(this.loggedinUser){
            return this,this.loggedinUser.roles.some(role=>role.toLowerCase()==="ttlapprover")
        }else{
            return false;
        }
    }

    get canEditWorkOrder():boolean{
        if (this.loggedinUser) {
            return this.loggedinUser.roles.some(role => role.toLowerCase() === 'shop')
        }
        else {
            return false;
        }
    }

    get canViewAuditLog():boolean{
        if (this.loggedinUser) {
            return this.loggedinUser.roles.some(role => role.toLowerCase() === 'approver' || role.toLowerCase() === 'admin')
        }
        else {
            return false;
        }
    }


    static get userAccessType() {
        const loggedinUserRoles: string[] | undefined = AuthHelper.user?.roles;
        const view: UserAccessType = loggedinUserRoles?.find(x => x.toLowerCase() === 'shop') ? UserAccessType.Vendor : UserAccessType.GSC;
        return view;
    }
}