import { environment } from "../../environments/environment";
export const config = Object.freeze( {
    /**
     * @param {string} client_id
     * @description the id of the OAuth2 client of the application
     * @required
     */
    client_id: environment.OAUTH_CLIENT,
    /**
     * @param {string} authority
     * @description the full url of the tenant,  https://accounts.maersk.com/<tenant-alias/tenant-id>
     * @required
     */
    authority: environment.FORGEROCK_AUTHORITY,
    /**
     * @param {string} redirect_uri
     * @description the exact url whitelisted in the OAuth2 client_id's configuration
     * @required
     */
    //redirect_uri: "http://localhost:4200/oauth2/callback",
    redirect_uri: environment.REDIRECT_URL,
    /**
     * @param {string} post_logout_uri
     * @description url where to redirect window after logout is completed
     * @optional
     */
    //post_logout_uri: "http://localhost:4200/oauth2/callback",
    post_logout_uri: environment.POST_LOGOUT_URL,
    /**
     * @param {function} login_redirect_cb
     * @description the function that will be called after OAuth2 flow is completed
     * @optional but is highly recommended to be used at the callbackUrl/redirect_uri location
     */
    login_redirect_cb: (response:any, error:any)=> {
      if (response) {
        console.log("auth response", response);
      } else if (error) {
        console.log("auth error", error);
      }
    },
    response_type: "code",
    storage: {
      /**
     * @param {string} type
     * @description the storage where to store the tokens
     * @optional
     * @default "localStorage"
     */
      type: "localStorage"
    },
  });