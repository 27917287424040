import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Renderer2,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Theme } from '../../theme/theme';
import { ThemeService } from '../../theme/theme.service';
import { FirstWordPipe } from '../../pipes/first-word.pipe';
import { HeaderComponent } from '../../layouts/header/header.component';
import { SideMenuComponent } from '../../layouts/side-menu/side-menu.component';
import '@maersk-global/mds-components-core/mc-button';
import '@maersk-global/mds-components-core/mc-label';
import '@maersk-global/mds-components-core/mc-toast';
import { BehaviorSubject, ReplaySubject, takeUntil } from 'rxjs';
import { ToastrService } from '../../services/toastr-service';
import { MasterDataService } from '../../services/master-data.service';
import { utils } from '../../helper/utils';
import { animate, style, transition, trigger } from '@angular/animations';
import { LogLevel } from "@grafana/faro-web-sdk";
import { RumInit } from '@maersk-global/telemetry-web-sdk';
import { environment } from '../../../environments/environment';
import { AuthHelper } from '../../auth/auth-helper';

@Component({
  selector: 'app-shell',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    FirstWordPipe,
    HeaderComponent,
    SideMenuComponent,
  ],
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ShellComponent implements AfterViewInit {
  title = 'AEMS';
  clickEvent: string = '';
  buttonLabel: string = 'Display Message';
  currentTheme: Theme = Theme.LIGHT; // Set the default theme to light
  statusCode: string = '';
  toastMessage$ = new BehaviorSubject<{ message: string, type: string }>({ message: '', type: '' });
  @ViewChild('warningToastTrigger', { read: ElementRef, static: false })
  warningToastTrigger!: ElementRef<HTMLElement>;

  @ViewChild('successToastTrigger', { read: ElementRef, static: false })
  successToastTrigger!: ElementRef<HTMLElement>;

  @ViewChild('errorToastTrigger', { read: ElementRef, static: false })
  errorToastTrigger!: ElementRef<HTMLElement>;
  toggleMenu:boolean=false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private themeService: ThemeService,
    private renderer: Renderer2,
    private toastrService: ToastrService,
    private masterDataService: MasterDataService
  ) {
    console.log("shell activated")
    this.themeService.setTheme(this.currentTheme, this.renderer);
    this.title = $localize`${this.title}`;
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    const toastTriggers = {
      warning: this.warningToastTrigger,
      success: this.successToastTrigger,
      error: this.errorToastTrigger
    };
    this.toastrService.init(toastTriggers);
    this.toastrService.getToastMessage()
    .pipe(takeUntil(this.destroyed$))
    .subscribe(toast => {
      this.toastMessage$.next(toast);
  });
  this.registerRumTelemetry();
  }
  onMenuClicked(){
 this.toggleMenu=!this.toggleMenu;
  }

  private registerRumTelemetry(){
    const faro = RumInit({
      app: {
        name: 'aems-web-cdt',
        version: '1.0.0',
        environment:"cdt"
      },
      apiKey: environment.MopRumApiKey,
      useSendBeacon: true,
      sso_user:true,
      //ignoreUrls:['http://localhost:4200/', '.*pensieve.*'],
      instrumentations:{
        interactions: true,
        console:false
      },
      debug: false, // false: Prevent showing log,error,etc captured in browser console
      traceLog: false, // Sends Traces to Loki ( For easy querying of traces)
      propagateTraceHeaderCorsUrls: [new RegExp('.*api.*')]
    });
    let user=AuthHelper.user;
    console.log("shell user",user);
    faro.api.setUser({
      id:user?.id.toString(),
      email:user?.email,
      username:user?.username
    })
  }
}
