import {AbstractControl, ValidationErrors,FormGroup, ValidatorFn } from '@angular/forms';
import { WarningSharedService } from '../../warning-shared-service';
import { HeaderForm } from "../../../../form-model/cwo-header.form.model";

export function locationMismatchValidatorWarning(
  warningSharedService: WarningSharedService
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let headerForm = control as FormGroup<HeaderForm>;
    const equipment = headerForm.controls.proxyEquipmentNumberForAsyncValidations.value;
    const shop = headerForm.controls.shopCode.value;
    warningSharedService.deleteWarning(locationMismatchValidatorWarning.name);
    if (!equipment || !shop) {
      return null;
    }

    if (equipment.presentLocation !== shop.locationCode) {
      let warningObj = {validatorName: locationMismatchValidatorWarning.name, message: 'Shop location does not match the equipment location'}
      warningSharedService.addWarning(warningObj);
    }
    return null;
  }
}


