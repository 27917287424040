
import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";
import { Mode } from "../../../models/mode.model";
import { CreateWorkOrderForm } from "../../../form-model/create-work-order.form.model";
import { Shop } from "../../../models/shop.model";

export function shopLimitValidator(
  ): ValidatorFn {
    return (control: AbstractControl) => {
      let createWorkOrderForm = control as FormGroup<CreateWorkOrderForm>;
      let shop = createWorkOrderForm?.controls?.headerForm?.controls?.shopCode?.value as Shop;
      let mode = createWorkOrderForm?.controls?.headerForm?.controls.mode?.value as Mode;
      let totalCost = createWorkOrderForm?.controls?.totalCostForm?.controls?.totalCost?.value;
  
      if (!shop || !mode) {
        return null;
      }

      if(shop.shopRepairCostAllocations){
        let shopRepairDetail = shop.shopRepairCostAllocations;//.find(sr => sr.shopCode === shop.code && sr.repairMode === mode.code);
        if(shopRepairDetail && shopRepairDetail.maxOrderCost < 0){
            return {shopLimitValidator : {value : "Shop material limit is not present for this combination of shop and mode"}};
        }
        else if(shopRepairDetail && totalCost > shopRepairDetail.maxOrderCost){
            return {shopLimitValidator : {value : `The total amount to be paid is more than the shop material limit set for given mode '${mode.code}' and shop code '${shop.code}'. Maximum limit is ${shopRepairDetail.maxOrderCost}`}};
        }
      }
      return null;
    };
  }