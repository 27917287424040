<form [formGroup]="damageAndRepairForm">
  <app-error [formContainer]="damageAndRepairForm"></app-error>
  <div class="aems-card line-item-container m-b-12" formArrayName="repairLineItems">
    @for (repairLineItem of repairLineItems.controls; track repairLineItem ;let i= $index) {
    <app-repair-line-item @removeRepairLineItem [repairLineItem]="repairLineItem" [index]="i"
      [mode]="mode()"
      [shop]="shop()"
      [equipment]="equipment()"
      [containerGrades]="containerGrades()"
      [damages]="damages()"
      [repairs]="repairs()"
      [repairLocations]="repairLocations()"
      [tpiCodes]="tpiCodes()"
      [existingRepairLineItem]="existingRepairLineItems?.[i]"
      (repairLineItemRemoved)="onRemoveRepairLineItem($event)"
      [class.error-on-form-group]="repairLineItem.hasError('repairCodeAndRepairLocationUnique')"
      [workOrderCurrency]="this.workOrderCurrency()"
      ></app-repair-line-item>
    }
    <div class="add-new-line-item-container">
      <mc-button class="m-t-20 m-l-20" label="Add repair item" variant="outlined" appearance="primary" fit="small"
        [disabled]="addRepairLineItemButtonDisabled()" padding="default" (click)="onAddRepairLineItemClicked()">
      </mc-button>
    </div>

  </div>
</form>
<mc-modal heading="Delete Repair Item" fit="small" dimension="small" [open]="openDeleteLineItemConfirmation" (closed)="onDeleteLineItemConfirmationClosed()">
  <p style="font-size: 0.8rem;">The repair item will be deleted permanetly. Are you sure you want to delete?</p>
  <mc-button slot="secondaryAction" dialogaction="cancel" appearance="neutral" variant="outlined" (click)="onDeleteLineItemConfirmationCancel()">Cancel</mc-button>
  <mc-button slot="primaryAction" appearance="primary" (click)="onDeleteLineItemConfirmation()">Yes</mc-button>
</mc-modal>