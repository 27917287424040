import { User } from '../models/user.model';
import { config } from './auth-config';
import { UserAgentPublicApp } from '@maersk-global/iam-al';
const al = new UserAgentPublicApp(config);
export class AuthHelper {
  private static isRefreshing:boolean=false;
  private static promise:Promise<any>;
  public static get accessToken(): string {
    return al.storage.access_token;
  }

  public static get refreshToken(): string {
    return al.storage.refresh_token;
  }

  public static isAccessTokenPresentAndValid(): boolean {
    console.log(al.storage);
    if (al.storage.access_token && al.storage.expiry_date) {
      return Date.now() <= al.storage.expiry_date ? true : false;
    }
    return false;
  }
  public static redirectToLogin(): void {
    al.loginRedirect({ scopes: ['openid', 'email', 'profile'] });
  }

  public static async refreshAccessToken(){
   
    if(!this.isRefreshing){
      this.isRefreshing=true;
      console.error("refreshing token called from interceptor")
      this.promise = await al.refreshTokens({}).then((result:any) => {
        al.storage.access_token = result.access_token;
        al.storage.expiry_date = Date.now() + result.expires_in * 1000;
        al.storage.refresh_token = result.refresh_token;
        al.storage.id_token = result.id_token;
        this.isRefreshing=false
      }).catch((error:any) => {
        console.log('error', error);
        this.redirectToLogin();
      });
    }
    return this.promise;
  }

  public static logout(): void {
    localStorage.clear();
    al.logoutRedirect();
  }
  
  static set user(user:User){
    localStorage.setItem('user',JSON.stringify(user));
  }
  static get user():User|null{
    if(localStorage.getItem('user')===null){
           return null;
    }
    return JSON.parse(localStorage.getItem('user')??"{}");
  }
}
