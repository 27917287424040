import { AbstractControl, ValidatorFn } from "@angular/forms";
import { Equipment } from "../../../../models/equipment.model";
import { ContainerGrade } from "../../../../models/container-grade.model";
import { Mode } from "../../../../models/mode.model";
import { WarningSharedService } from "../../warning-shared-service";

export function ptiStatusValidator(
  index: number,
  equipment: Equipment | null,
  warningSharedService: WarningSharedService
): ValidatorFn {
  return (control: AbstractControl) => {
    let enteredRepairCode = control.value as string;
    if (!enteredRepairCode) {
      return null;
    }
    if (!equipment) {
      return null;
    }
    if (!equipment.ptiStatus) {
      return null;
    }
    let raiseWarining = false;
    if (equipment.ptiStatus.color == "Blue") {
      raiseWarining = true;
    }
    if (equipment.ptiStatus.color == "Green") {
      if (enteredRepairCode == PtiRepairCodeMapping.ShortPTI
        || enteredRepairCode == PtiRepairCodeMapping.LongPTI) {
        raiseWarining = true;
      }
    }
    if (equipment.ptiStatus.color == "Red") {
      if (equipment.ptiStatus.description == "Short PTI") {
        if (enteredRepairCode == PtiRepairCodeMapping.VisualInspection || enteredRepairCode == PtiRepairCodeMapping.LongPTI) {
          raiseWarining = true;
        }
      }
      if (equipment.ptiStatus.description == "*PTI/SPTI") {
        if (enteredRepairCode == PtiRepairCodeMapping.VisualInspection || enteredRepairCode == PtiRepairCodeMapping.ShortPTI) {
          raiseWarining = true;
        }
      }
    }
    let validatorName = ptiStatusValidator.name + index;
    if (raiseWarining) {
      let warningObj = {
        validatorName: validatorName,
        message: 'WorkOrder created against the suggested Container PTI status, it might be rejected. Please enter comments why this repair is required.'
      }
      warningSharedService.addWarning(warningObj);
    } else {
      warningSharedService.deleteWarning(validatorName)
    }
    return null;
  }
}
interface PtiRepairCodeMapping {
  [name: string]: string | undefined;
}
export const ptiRepairCodeMapping: PtiRepairCodeMapping = {
};
export const PtiRepairCodeMapping = {
  VisualInspection: "0948",
  ShortPTI: "0942",
  LongPTI: "0940"
}