<mc-table [data]="tableData" fit="small" [columns]="repairLineItemTableColumns" tabularFigures="true" expand>
@for (item of tableData; track $index) {
@if(item.parts && item.parts.length>0){
    <mc-table [slot]="item.id + '_expanded'" [columns]="partsTableColumn" [data]="item.parts" fit="small"></mc-table>
}
<div [slot]="item.id +'_attachments'">
    <app-file-upload type="compact" [readOnly]="true" [inputFiles]="item.files"></app-file-upload>
</div>
}

</mc-table>