<div class="aems-card m-b-12">

  @if (sortedWorkOrderRemark.length>0) {
  <div class="remark-list">
    @for (remark of sortedWorkOrderRemark ; track $index ) {
    <span class="remark-user">{{remark.createUserId}}</span>&nbsp;
    <span class="remark-time">
      {{remark.createTimestamp| date }}</span>
    <div class="m-b-4">
      <span class="remark-text ">{{remark.maintenanceOrderRemarkText}}</span>
    </div>
    }
  </div>
  }
  <form [formGroup]="remarkForm" (ngSubmit)="onSaveRemark()">
    <mc-textarea label="Remarks" ngDefaultControl maxlength="255"
      placeholder="Please indicate general condition of box, main damage consists, wear and team share of repair, repair in other mode required."
      rows="5" fit="small" formControlName="remark">
    </mc-textarea>
    <mc-button label="Save Remark" variant="outlined" appearance="primary" fit="small" padding="default" class="m-t-16"
      type="submit" [loading]="postingRemark" [disable]="this.remarkForm.valid" [hidden]="workOrderMode==='create'"></mc-button>
  </form>
</div>