import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit, signal } from '@angular/core';
import { MaintenanceOrderResponse } from '../../../models/maintenance-order-response.model';
import { utils } from '../../../helper/utils';
import { TableColumn } from '@maersk-global/mds-components-core/mc-table/types';
import { WorkOrderService } from '../../../services/work-order.service';
import { forkJoin, ReplaySubject, takeUntil } from 'rxjs';
import { MaintenanceOrderChangeResult } from '../../../models/maintenance-order-change-result.model';

interface AuditLogData{
  date:Date;
  user:string;
  description:string;
} 
@Component({
  selector: 'app-audit-log',
  standalone: true,
  imports: [],
  templateUrl: './audit-log.component.html',
  styleUrl: './audit-log.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuditLogComponent implements OnInit{
  @Input() workOrder!: MaintenanceOrderResponse | null;
  @Input() workOrderId!: string;
  workOrderStatus = signal("");
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  auditColumns: Array<TableColumn> = [
    {
      id: 'date',
      label: 'Date',
      dataType: {
        type: 'string',
        formatter: (value) => {
          const date = new Date(value as string);
          return utils.formatDate(date);
        }
      }
    },
    {
      id: 'user',
      label: 'User',
    },
    {
      id: 'description',
      label: 'Description',
    }
  ];
  auditLogTotal = signal<AuditLogData[]>([]);
  auditLog: MaintenanceOrderChangeResult = {} as MaintenanceOrderChangeResult;
  get workOrderStatusDescription(): string {
    return this.workOrder ? utils.getMaintenanceOrderStatusText(this.workOrder.maintenanceOrderStatusCode).description : '';
  }
  constructor(private workOrderService : WorkOrderService ) { }
  ngOnInit(): void {
    this.getAuditLog();
  }
  private getAuditLog() {
    forkJoin([
      this.workOrderService.getAuditLogs(this.workOrderId),
    ]).pipe(takeUntil(this.destroyed$)).subscribe(([auditLog]) => {
      this.auditLog = auditLog;
      this.updateAuditLogColumns();
    });
  }
  updateAuditLogColumns() {
    let auditLogData: AuditLogData[] = [];
    auditLogData = this.auditLog.changes?.map((changeResult) => {
      return {
        date: changeResult.timestamp,
        user: changeResult.createdBy.emailAddress,
        description: "The " + changeResult.propertyName + " changed from " + (changeResult.oldValue === '' ? 'EMPTY' : changeResult.oldValue) + " to " + (changeResult.newValue === '' ? 'EMPTY' : changeResult.newValue)
      };
    });
    this.auditLogTotal.set([...auditLogData]);
  }
}