<div>
    <app-header (menuClicked)="onMenuClicked()"></app-header>
    <div style="display: flex; flex-direction: row">
      <app-side-menu [toggleMenu]="toggleMenu"></app-side-menu>
      <div class="mds top-level-container">
        <router-outlet></router-outlet>
      </div>
    </div>
    <mc-toast fit="small" appearance="warning" position="top-right">
      <mc-button style="display: none;" #warningToastTrigger appearance="neutral" label="Warning" slot="trigger"></mc-button>
      <mc-notification icon="exclamation-triangle" [body]="(toastMessage$ | async)?.message"></mc-notification>
    </mc-toast>
    <mc-toast fit="small" appearance="success" position="top-right">
      <mc-button style="display: none;" #successToastTrigger appearance="neutral" label="Success" slot="trigger"></mc-button>
      <mc-notification icon="check-circle" [body]="(toastMessage$ | async)?.message"></mc-notification>
    </mc-toast>
    <mc-toast fit="small" appearance="error" position="top-right">
      <mc-button style="display: none;" #errorToastTrigger appearance="neutral" label="Error" slot="trigger"></mc-button>
      <mc-notification icon="exclamation-octagon" [body]="(toastMessage$ | async)?.message"></mc-notification>
    </mc-toast>
  </div>
  