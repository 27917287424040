import {
    Directive,
    HostBinding,
    HostListener,
    Output,
    EventEmitter
  } from "@angular/core";
  @Directive({
    selector: "[aems-drag-drop]",
    standalone: true
  })
  export class DragDropDirective{
    @Output() onFileDropped = new EventEmitter<FileList>();
    @HostBinding("class") private class = "aems-file-upload-drag-drop";
    @HostBinding("style.background-color") private background:string="";
    @HostBinding("style.opacity") private opacity = "1";
    @HostListener("dragover", ["$event"]) onDragOver(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.class="drag";
      console.log("drag over");
    }
    @HostListener("dragleave", ["$event"]) public onDragLeave(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.class="aems-file-upload-drag-drop";
      console.log("drag leave");
    }
    @HostListener("drop", ["$event"]) public onDrop(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.class="aems-file-upload-drag-drop"
      const files = event.dataTransfer?.files;
      if (files) {
        this.onFileDropped.emit(files);
      }
      console.log("drop");
    }
  }